import React from 'react'
import { BrowserRouter,Routes,Route } from 'react-router-dom'
import Home from './pages/Home/Home'
import MyBookings from './components/LoginPageDetails/MyBookings'
import MyTravellersList from './components/LoginPageDetails/MyTravellersList'
import MyProfile from './components/LoginPageDetails/MyProfile'
import BillingAddress from './components/LoginPageDetails/BillingAddress'
import Notification from './components/LoginPageDetails/Notification'
import Header from './components/Header/Header'
import About from './pages/Master about/About'
import PrivacyPolicy from './pages/Master Privacy Policy/PrivacyPolicy'
import Faq from './pages/Master Faq/Faq'
import TermsCondition from './pages/Master Terms and Condition/TermsCondition'
import Firstbus from './components/OfferPages/Firstbus'
import TravelDetails from './components/travelDetails/TravelDetails'
import ContactUs from './pages/Master Contact Us/ContactUs'


const Routers = () => {

  return (

    <>

    <BrowserRouter>
    <Header/>
    <Routes>
        <Route path='/'  element={<Home/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/privacy' element={<PrivacyPolicy/>}/>
        <Route path='/faq' element={<Faq/>}/>
        <Route path='/terms' element={<TermsCondition/>}/>
        <Route path ="/contact" element={<ContactUs/>}/>
        <Route path="/mybookings"  element={<MyBookings/>} />
        <Route path='/my_travellers_list' element={<MyTravellersList/>} />
        <Route path='/profile' element={<MyProfile/>} />
        <Route path="/billing_address" element ={<BillingAddress/>} />
        <Route path="/notification" element ={<Notification/>} />
        <Route path='/firstbus' element={<Firstbus/>}/>
        <Route path='/travel_details' element={<TravelDetails/>}/>
    </Routes>
    </BrowserRouter>

    </>

  )
}

export default Routers