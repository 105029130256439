import React from 'react'
import './App.css';
import Home from './pages/Home/Home';
import Corousel from './pages/Home/Corousel/Corousel';
import Pcalendar from './pages/Home/Calendar/Pcalendar';
import Routers from './Routers';




function App() {

  return (
    <>

    <Routers/>

    </>
  );
}

export default App;
