import React, { useState } from 'react'
import { IoIosEye, IoIosEyeOff } from "react-icons/io";

const TermsCondition = () => {

    const [terms, setTerms] = useState(0)

    return (
        <>


            <section className="mt-4">
                <div className="container">
                    <div className="row ">
                        <h4 className="" > Terms and Conditions </h4>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
                            <div className="accordion">
                                <div className="accordion-section">
                                    <div className="accordion-header" >
                                        <h5>Role of BookUrself</h5>
                                        <div className="arrow">
                                            {



                                                terms === 1 ? <IoIosEyeOff className='acc-icon' onClick={() => setTerms(0)} /> : <IoIosEye className='acc-icon1' onClick={() => setTerms(1)} />

                                            }

                                        </div>
                                    </div>
                                    {
                                        terms === 1 && <div className="accordion-content">


                                            <p className="">1 - BookUrSelf provides the only technology platform that connects destination travellers with bus companies. It does not operate buses or provide transportation services to its users. In addition, BookUrSelf does not act as a representative of any bus company in providing the above technology platform services. </p>


                                            <p className="">2 - Bus ticket booking vouchers issued by BookUrSelf to its users are based solely on seat availability provided or updated by the bus company.</p>
                                            <p className="">3 - BookUrSelf has no influence over the amenities, services, routes, prices, schedule, bus type, seat availability, or any other information related to the bus service as it is provided by the individual bus operator.</p>
                                        </div>
                                    }
                                </div>
                                <div className="accordion-section">
                                    <div className="accordion-header" >
                                        <h5>Limitations OF BookUrSelf 's Liability</h5>
                                        <div className="arrow">
                                            {

                                                terms === 2 ? <IoIosEyeOff className='acc-icon' onClick={() => setTerms(0)} /> : <IoIosEye className='acc-icon1' onClick={() => setTerms(2)} />

                                            }
                                        </div>
                                    </div>
                                    {
                                        terms === 2 && <div className="accordion-content">
                                            <p className="">1 - Bus departure or arrival on time;</p>
                                            <p className="">2 - Actions of employees, agents or representatives of bus companies;</p>
                                            <p className="">3 - If the condition of the bus, seats, etc. differs from the customer's expectations or the bus company's explanation;</p>
                                            <p className="">4 - Cancellation of trip for any reason;</p>
                                            <p className="">5 - Loss or damage of the luggage of the customer</p>
                                            <p className="">6 - If the bus operator changes your seat for any reason whatsoever</p>
                                            <p className="">7 - The bus operator may notify you of the wrong boarding point for issuing your booking confirmation voucher or change this boarding point with or without notice to BookUrSelf or you.</p>
                                            <p className="">8 - The bus operator will use another vehicle to transport the user from the designated pick-up point to the actual bus departure point. </p>
                                        </div>
                                    }
                                </div>
                                <div className='accordion-section'>
                                    <div className="accordion-header" >
                                        <h5>The User Responsibilities</h5>
                                        <div className="arrow">
                                            {

                                                terms === 3 ? <IoIosEyeOff className='acc-icon' onClick={() => setTerms(0)} /> : <IoIosEye className='acc-icon1' onClick={() => setTerms(3)} />
                                            }
                                        </div>
                                    </div>
                                    {
                                        terms === 3 && <div className="accordion-content">
                                            <p className="">1 - Users are advised to call the bus operator to obtain the exact pick-up location and other information necessary to board or travelling on that journey.</p>
                                            <p className="">2 - When boarding the bus, the user must present a copy of the ticket and a valid ID such as his Adhar card, passport, PAN card, voter ID, or any other ID issued by a government agency.</p>
                                            <p className="">3 - The User must be at the boarding location at least 30 minutes before the scheduled departure time. </p>
                                            <p className="">4 - All tickets issued are non-transferable.</p>
                                        </div>
                                    }
                                </div>
                                <div className="accordion-section">
                                    <div className="accordion-header" >
                                        <h5>Cancellation of Ticket</h5>
                                        <div className="arrow">
                                            {

                                                terms === 4 ? <IoIosEyeOff className='acc-icon' onClick={() => setTerms(0)} /> : <IoIosEye className='acc-icon1' onClick={() => setTerms(4)} />

                                            }

                                        </div>
                                    </div>
                                    {
                                        terms === 4 && <div className="accordion-content">
                                            <p className="">1 - Cancellation of tickets can be done either through the User’s login in the BookUrSelf’s website or mobile application, or by calling on the customer care number;</p>
                                            <p className="">2 - Any cancellation is subject to such cancellation charges as mentioned on the ticket.</p>
                                        </div>
                                    }
                                </div>
                                <div className="accordion-section">
                                    <div className="accordion-header" >
                                        <h5>Rescheduling of Ticket</h5>
                                        <div className="arrow">
                                            {

                                                terms === 5 ? <IoIosEyeOff className='acc-icon' onClick={() => setTerms(0)} /> : <IoIosEye className='acc-icon1' onClick={() => setTerms(5)} />

                                            }
                                        </div>
                                    </div>
                                    {
                                        terms === 5 && <div className="accordion-content">
                                            <p className="">1 - Rescheduling (i.e. change of date of travel) of the tickets can be done through the User’s login in the BookUrSelf’s website or mobile application, or by reaching out to the customer support team;</p>
                                            <p className="">2 - Rescheduling is an option provided only by select bus operators. The policy for the same shall be available on the e-ticket.</p>
                                            <p className="">3 - Rescheduling a ticket is subject to charges as mentioned on the e-ticket. Fare difference, if applicable, shall be borne by the customer. However, if the fare of the rescheduled ticket is lower than the current fare, the fare difference shall not be refunded.</p>
                                            <p className="">4 - Rescheduling a ticket can be availed only once per booking, if applicable. Once the travel date change option is availed, the ticket cannot be further cancelled.</p>
                                            <p className="">5 - Tickets are non-transferable and the originally booked passengers are to travel upon such rescheduling.</p>
                                        </div>
                                    }
                                </div>
                                <div className='accordion-section'>
                                    <div className="accordion-header" >
                                        <h5>Miscellaneous</h5>
                                        <div className="arrow">
                                            {

                                                terms === 6 ? <IoIosEyeOff className='acc-icon' onClick={() => setTerms(0)} /> : <IoIosEye className='acc-icon1' onClick={() => setTerms(6)} />

                                            }
                                        </div>
                                    </div>
                                    {
                                        terms === 6 && <div className="accordion-content">
                                            <p className="">1 - The Bus Operator will comply with all laws including, but not limited to, the Motor Vehicle Law and its regulations, any applicable regulations, guidelines or instructions issued or issued by the central or relevant state governments. You are solely responsible. Criminal prosecution (including, without limitation, fines or penalties) arising from violation of any such laws, rules, regulations or policies, including fare management policies or instructions from the applicable RTO, shall be borne by the bus operator. shall be The User agrees to record complaints arising from the cancellation or lack of service due to enforcement authority actions that have occurred with the bus company.</p>
                                            <p className="">2 - User Agreement and Privacy Policy of the BookUrSelf website apply. BookUrSelf reserves the right to refuse any claim if there is abuse/abuse of the offer by the user or if cancellation/claim is not permitted under the offer.</p>
                                            <p className="">3 - Terms of Use are governed by Indian law. Any dispute arising out of or relating to this offer shall be subject to the exclusive jurisdiction of the competent courts of Bangalore.</p>
                                            <p className="">4 - The maximum liability of BookUrSelf in the event of any claim arising out of this offer shall not exceed the amount paid by the user in the context of the underlying transaction.</p>
                                            <p className="">5 - BookUrSelf shall not be liable for any indirect, punitive, special, incidental or consequential damages arising out of or in connection with the offer.</p>
                                        </div>
                                    }
                                </div>
                                <div className='accordion-section'>
                                    <div className="accordion-header"  >
                                        <h5>Refer & Earn</h5>
                                        <div className="arrow">
                                            {

                                                terms === 7 ? <IoIosEyeOff className='acc-icon' onClick={() => setTerms(0)} /> : <IoIosEye className='acc-icon1' onClick={() => setTerms(7)} />

                                            }
                                        </div>
                                    </div>
                                    {
                                        terms === 7 && <div className="accordion-content" >
                                            <p className="">1 - To create an account and become a BookUrSelf user, you must first register on the BookUrSelf website or mobile application.</p>
                                            <p className="">2 - To take advantage of the programme, a user must first register for the refer and earn programme by paying a registration fee (for example, Rs. 100), which may alter depending on the market for new users.</p>
                                            <p className="">3 - The user who signed up for the refer and earn programme will receive a special URL with a code, which they must share with others in order to refer them. For each successfully referred registered user, the referrer will receive 50 Rs cash point in their wallet.</p>
                                            <p className="">4 - When a referred user successfully registers, the user will receive 50 Rs cash points in their wallet.</p>
                                            <p className="">5 - Earned cash point can be used to book travel ticket in BookUrSelf.</p>
                                            <p className="">6 - Wallet cash point cannot be transferred to other user or to bank account.</p>
                                            <p className="">7 - User cannot refer themselves.</p>
                                            <p className="">8 - user can refer to N number of user and earn cash point.</p>
                                            <p className="">9 - referred user must use the share link or use referral code to register and earn cash points.</p>
                                            <p className="">10 - Only one user ID and be created using one email ID or mobile number.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default TermsCondition