import { BsBusFrontFill } from "react-icons/bs";
import { FaCalendar } from "react-icons/fa";



export const travelledDetail = [

    {
        id:1,
        busIcon:<BsBusFrontFill/>,
        busDetail: "IntrCity SmartBus",
        status1 :"Upcoming",
        status2 :"Travelled",
        status3 :"Cancelled",
        from : "Hyderabad",
        to :"Bangalore",
        dateIcon: <FaCalendar/>,
        dateDetail:"2024-08-10",
        cancelTicket:"Cancel Ticket",
        printTicket :"Print Ticket"
    },

    {
        id:2,
        busIcon:<BsBusFrontFill/>,
        busDetail: "IntrCity SmartBus",
        status1 :"Upcoming",
        status2 :"Travelled",
        status3 :"Cancelled",
        from : "Mumbai",
        to :"Chennai",
        dateIcon: <FaCalendar/>,
        dateDetail:"2024-07-05",
        cancelTicket:"Cancel Ticket",
        printTicket :"Print Ticket"
    },

    {
        id:3,
        busIcon:<BsBusFrontFill/>,
        busDetail: "IntrCity SmartBus",
        status1 :"Upcoming",
        status2 :"Travelled",
        status3 :"Cancelled",
        from : "Goa",
        to :"Delhi",
        dateIcon: <FaCalendar/>,
        dateDetail:"2024-06-04",
        cancelTicket:"Cancel Ticket",
        printTicket :"Print Ticket"
    },

    {
        id:4,
        busIcon:<BsBusFrontFill/>,
        busDetail: "IntrCity SmartBus",
        status1 :"Upcoming",
        status2 :"Travelled",
        status3 :"Cancelled",
        from : "Pune",
        to :"Mumbai",
        dateIcon: <FaCalendar/>,
        dateDetail:"2024-04-28",
        cancelTicket:"Cancel Ticket",
        printTicket :"Print Ticket"
    },

    {
        id:5,
        busIcon:<BsBusFrontFill/>,
        busDetail: "IntrCity SmartBus",
        status1 :"Upcoming",
        status2 :"Travelled",
        status3 :"Cancelled",
        from : "Mysore",
        to :"Bangalore",
        dateIcon: <FaCalendar/>,
        dateDetail:"2024-03-10",
        cancelTicket:"Cancel Ticket",
        printTicket :"Print Ticket"
    },
    {
        id:6,
        busIcon:<BsBusFrontFill/>,
        busDetail: "IntrCity SmartBus",
        status1 :"Upcoming",
        status2 :"Travelled",
        status3 :"Cancelled",
        from : "Nagpur",
        to :"Agra",
        dateIcon: <FaCalendar/>,
        dateDetail:"2023-10-10",
        cancelTicket:"Cancel Ticket",
        printTicket :"Print Ticket"
    },
    {
        id:7,
        busIcon:<BsBusFrontFill/>,
        busDetail: "IntrCity SmartBus",
        status1 :"Upcoming",
        status2 :"Travelled",
        status3 :"Cancelled",
        from : "Jodhpur",
        to :"Surat",
        dateIcon: <FaCalendar/>,
        dateDetail:"2024-08-10",
        cancelTicket:"Cancel Ticket",
        printTicket :"Print Ticket"
    },
    {
        id:8,
        busIcon:<BsBusFrontFill/>,
        busDetail: "IntrCity SmartBus",
        status1 :"Upcoming",
        status2 :"Travelled",
        status3 :"Cancelled",
        from : "kashmir",
        to :"KanyaKumari",
        dateIcon: <FaCalendar/>,
        dateDetail:"2024-06-03",
        cancelTicket:"Cancel Ticket",
        printTicket :"Print Ticket"
    },
    {
        id:9,
        busIcon:<BsBusFrontFill/>,
        busDetail: "IntrCity SmartBus",
        status1 :"Upcoming",
        status2 :"Travelled",
        status3 :"Cancelled",
        from : "kolkatta",
        to :"Tirupati",
        dateIcon: <FaCalendar/>,
        dateDetail:"2024-06-03",
        cancelTicket:"Cancel Ticket",
        printTicket :"Print Ticket"
    },
    {
        id:10,
        busIcon:<BsBusFrontFill/>,
        busDetail: "IntrCity SmartBus",
        status1 :"Upcoming",
        status2 :"Travelled",
        status3 :"Cancelled",
        from : "Hyderabad",
        to :"Bangalore",
        dateIcon: <FaCalendar/>,
        dateDetail:"2024-05-08",
        cancelTicket:"Cancel Ticket",
        printTicket :"Print Ticket"
    },

    {
        id:11,
        busIcon:<BsBusFrontFill/>,
        busDetail: "IntrCity SmartBus",
        status1 :"Upcoming",
        status2 :"Travelled",
        status3 :"Cancelled",
        from : "Mumbai",
        to :"Chennai",
        dateIcon: <FaCalendar/>,
        dateDetail:"2024-07-05",
        cancelTicket:"Cancel Ticket",
        printTicket :"Print Ticket"
    },

    {
        id:12,
        busIcon:<BsBusFrontFill/>,
        busDetail: "IntrCity SmartBus",
        status1 :"Upcoming",
        status2 :"Travelled",
        status3 :"Cancelled",
        from : "Goa",
        to :"Delhi",
        dateIcon: <FaCalendar/>,
        dateDetail:"2024-06-04",
        cancelTicket:"Cancel Ticket",
        printTicket :"Print Ticket"
    },

    {
        id:13,
        busIcon:<BsBusFrontFill/>,
        busDetail: "IntrCity SmartBus",
        status1 :"Upcoming",
        status2 :"Travelled",
        status3 :"Cancelled",
        from : "Pune",
        to :"Mumbai",
        dateIcon: <FaCalendar/>,
        dateDetail:"2024-04-28",
        cancelTicket:"Cancel Ticket",
        printTicket :"Print Ticket"
    },

    {
        id:14,
        busIcon:<BsBusFrontFill/>,
        busDetail: "IntrCity Smart",
        status1 :"Upcoming",
        status2 :"Travelled",
        status3 :"Cancelled",
        from : "Mysore",
        to :"Bangalore",
        dateIcon: <FaCalendar/>,
        dateDetail:"2024-03-10",
        cancelTicket:"Cancel Ticket",
        printTicket :"Print Ticket"
    },

    {
        id:15,
        busIcon:<BsBusFrontFill/>,
        busDetail: "IntrCity Smart",
        status1 :"Upcoming",
        status2 :"Travelled",
        status3 :"Cancelled",
        from : "Mysore",
        to :"Pune",
        dateIcon: <FaCalendar/>,
        dateDetail:"2024-04-06",
        cancelTicket:"Cancel Ticket",
        printTicket :"Print Ticket"
    },

    {
        id:1,
        busIcon:<BsBusFrontFill/>,
        busDetail: "IntrCity SmartBus",
        status1 :"Upcoming",
        status2 :"Travelled",
        status3 :"Cancelled",
        from : "Hyderabad",
        to :"Bangalore",
        dateIcon: <FaCalendar/>,
        dateDetail:"2024-08-10",
        cancelTicket:"Cancel Ticket",
        printTicket :"Print Ticket"
    },

    {
        id:2,
        busIcon:<BsBusFrontFill/>,
        busDetail: "IntrCity SmartBus",
        status1 :"Upcoming",
        status2 :"Travelled",
        status3 :"Cancelled",
        from : "Mumbai",
        to :"Chennai",
        dateIcon: <FaCalendar/>,
        dateDetail:"2024-07-05",
        cancelTicket:"Cancel Ticket",
        printTicket :"Print Ticket"
    },

    {
        id:3,
        busIcon:<BsBusFrontFill/>,
        busDetail: "IntrCity SmartBus",
        status1 :"Upcoming",
        status2 :"Travelled",
        status3 :"Cancelled",
        from : "Goa",
        to :"Delhi",
        dateIcon: <FaCalendar/>,
        dateDetail:"2024-06-04",
        cancelTicket:"Cancel Ticket",
        printTicket :"Print Ticket"
    },

    {
        id:4,
        busIcon:<BsBusFrontFill/>,
        busDetail: "IntrCity SmartBus",
        status1 :"Upcoming",
        status2 :"Travelled",
        status3 :"Cancelled",
        from : "Pune",
        to :"Mumbai",
        dateIcon: <FaCalendar/>,
        dateDetail:"2024-04-28",
        cancelTicket:"Cancel Ticket",
        printTicket :"Print Ticket"
    },

    {
        id:5,
        busIcon:<BsBusFrontFill/>,
        busDetail: "IntrCity SmartBus",
        status1 :"Upcoming",
        status2 :"Travelled",
        status3 :"Cancelled",
        from : "Mysore",
        to :"Bangalore",
        dateIcon: <FaCalendar/>,
        dateDetail:"2024-03-10",
        cancelTicket:"Cancel Ticket",
        printTicket :"Print Ticket"
    },
    {
        id:6,
        busIcon:<BsBusFrontFill/>,
        busDetail: "IntrCity SmartBus",
        status1 :"Upcoming",
        status2 :"Travelled",
        status3 :"Cancelled",
        from : "Nagpur",
        to :"Agra",
        dateIcon: <FaCalendar/>,
        dateDetail:"2023-10-10",
        cancelTicket:"Cancel Ticket",
        printTicket :"Print Ticket"
    },
    {
        id:7,
        busIcon:<BsBusFrontFill/>,
        busDetail: "IntrCity SmartBus",
        status1 :"Upcoming",
        status2 :"Travelled",
        status3 :"Cancelled",
        from : "Jodhpur",
        to :"Surat",
        dateIcon: <FaCalendar/>,
        dateDetail:"2024-08-10",
        cancelTicket:"Cancel Ticket",
        printTicket :"Print Ticket"
    },
    {
        id:8,
        busIcon:<BsBusFrontFill/>,
        busDetail: "IntrCity SmartBus",
        status1 :"Upcoming",
        status2 :"Travelled",
        status3 :"Cancelled",
        from : "kashmir",
        to :"KanyaKumari",
        dateIcon: <FaCalendar/>,
        dateDetail:"2024-06-03",
        cancelTicket:"Cancel Ticket",
        printTicket :"Print Ticket"
    },
    {
        id:9,
        busIcon:<BsBusFrontFill/>,
        busDetail: "IntrCity SmartBus",
        status1 :"Upcoming",
        status2 :"Travelled",
        status3 :"Cancelled",
        from : "kolkatta",
        to :"Tirupati",
        dateIcon: <FaCalendar/>,
        dateDetail:"2024-06-03",
        cancelTicket:"Cancel Ticket",
        printTicket :"Print Ticket"
    },
    {
        id:10,
        busIcon:<BsBusFrontFill/>,
        busDetail: "IntrCity SmartBus",
        status1 :"Upcoming",
        status2 :"Travelled",
        status3 :"Cancelled",
        from : "Hyderabad",
        to :"Bangalore",
        dateIcon: <FaCalendar/>,
        dateDetail:"2024-05-08",
        cancelTicket:"Cancel Ticket",
        printTicket :"Print Ticket"
    },

    {
        id:11,
        busIcon:<BsBusFrontFill/>,
        busDetail: "IntrCity SmartBus",
        status1 :"Upcoming",
        status2 :"Travelled",
        status3 :"Cancelled",
        from : "Mumbai",
        to :"Chennai",
        dateIcon: <FaCalendar/>,
        dateDetail:"2024-07-05",
        cancelTicket:"Cancel Ticket",
        printTicket :"Print Ticket"
    },

    {
        id:12,
        busIcon:<BsBusFrontFill/>,
        busDetail: "IntrCity SmartBus",
        status1 :"Upcoming",
        status2 :"Travelled",
        status3 :"Cancelled",
        from : "Goa",
        to :"Delhi",
        dateIcon: <FaCalendar/>,
        dateDetail:"2024-06-04",
        cancelTicket:"Cancel Ticket",
        printTicket :"Print Ticket"
    },

    {
        id:13,
        busIcon:<BsBusFrontFill/>,
        busDetail: "IntrCity SmartBus",
        status1 :"Upcoming",
        status2 :"Travelled",
        status3 :"Cancelled",
        from : "Pune",
        to :"Mumbai",
        dateIcon: <FaCalendar/>,
        dateDetail:"2024-04-28",
        cancelTicket:"Cancel Ticket",
        printTicket :"Print Ticket"
    },

    {
        id:14,
        busIcon:<BsBusFrontFill/>,
        busDetail: "IntrCity Smart",
        status1 :"Upcoming",
        status2 :"Travelled",
        status3 :"Cancelled",
        from : "Mysore",
        to :"Bangalore",
        dateIcon: <FaCalendar/>,
        dateDetail:"2024-03-10",
        cancelTicket:"Cancel Ticket",
        printTicket :"Print Ticket"
    },

    {
        id:15,
        busIcon:<BsBusFrontFill/>,
        busDetail: "IntrCity Smart",
        status1 :"Upcoming",
        status2 :"Travelled",
        status3 :"Cancelled",
        from : "Mysore",
        to :"Pune",
        dateIcon: <FaCalendar/>,
        dateDetail:"2024-04-06",
        cancelTicket:"Cancel Ticket",
        printTicket :"Print Ticket"
    }
]