import React, { useState } from "react";
import "./TravelDetailsCss/traveldetails.css";
import { MdOutlineKeyboardDoubleArrowDown } from "react-icons/md";
import { IoIosSearch } from "react-icons/io";
import { HiMiniArrowLongLeft, HiMiniArrowLongRight } from "react-icons/hi2";
import { TbCurrentLocation } from "react-icons/tb";
import { FaBusAlt } from "react-icons/fa";
import { MdOutlineStar } from "react-icons/md";
import { PiPlugLight } from "react-icons/pi";
import { GiCctvCamera, GiFlexibleLamp } from "react-icons/gi";
import { FaBottleWater } from "react-icons/fa6";
import { AiOutlineUser } from "react-icons/ai";
import { MdOutlineCancel } from "react-icons/md";

const TravelDetails = () => {
    const [first, setfirst] = useState(550);
    const [second, setSecond] = useState(2000);
    const [busPartnerSec, setBusPartnerSec] = useState(false);
    const [boardingPoint, setBoardingPoint] = useState(false);
    const [droppingPoint, setDroppingPoint] = useState(false);
    const [ameneties, setAmeneties] = useState(0);

    return (
        <>
            <div>
                <div className="travelDetailsContainer container mt-5 ">
                    <div className="row ">
                        <div className="col-md-3 p-0  ">
                            <div className="travelDetailSidebar ">
                                <div className="travelDetailSidebarFilter">
                                    <p>Filter</p>
                                    <p>Clear All</p>
                                </div>

                                <div className="travelDetailSidebarPriceDrop">
                                    <label htmlFor="pricedrop">Price Drop</label>
                                    <input
                                        type="checkbox"
                                        id="pricedrop"
                                        name="pricedrop"
                                        value="pricedrop"
                                    />
                                </div>

                                <div className="travelDetailSidebarBusType">
                                    <p style={{ fontWeight: " 600", paddingLeft: "6px" }}>
                                        Bus Type
                                    </p>

                                    <div className="travelDetailSidebarBusTypeItem">
                                        <div className="travelDetailSidebarBusTypeItemBox">
                                            <img src="/img/busTypeIcons/1.png" alt="" style={{}} />
                                            <p>AC</p>
                                        </div>
                                        <div className="travelDetailSidebarBusTypeItemBox">
                                            <img src="/img/busTypeIcons/2.png" alt="" />
                                            <p>Sleeper</p>
                                        </div>
                                        <div className="travelDetailSidebarBusTypeItemBox">
                                            <div style={{ position: "relative" }}>
                                                <img src="/img/busTypeIcons/1.png" alt="" />
                                                <span style={{}}></span>
                                            </div>
                                            <p>Non AC</p>
                                        </div>
                                        <div className="travelDetailSidebarBusTypeItemBox">
                                            <img src="/img/busTypeIcons/3.png" alt="" />
                                            <p>Seater</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="travelDetailSidebarPriceRange">
                                    <p style={{ fontWeight: " 600", paddingLeft: "6px" }}>
                                        Price Range
                                    </p>

                                    <div
                                        style={{ display: "flex", justifyContent: "space-between" }}
                                    >
                                        <span>{first}</span>
                                        <span>{second}</span>
                                    </div>

                                    <input
                                        type="range"
                                        id="points"
                                        name="points"
                                        value={second}
                                        min={first}
                                        max="2000"
                                        style={{ width: "100%" }}
                                        onChange={(e) => setSecond(e.target.value)}
                                    />
                                </div>

                                <div className="travelDetailSidebarDeparatureTime">
                                    <p style={{ fontWeight: " 600", paddingLeft: "6px" }}>
                                        Deparature Time
                                    </p>

                                    <div className="travelDetailSidebarDeparatureTimeBox ">
                                        <div className="travelDetailSidebarDeparatureTimeItem">
                                            <img src="/img/DeparatureTimeIcons/1.png" alt="" />
                                            <p>Before 10 AM</p>
                                        </div>

                                        <div className="travelDetailSidebarDeparatureTimeItem ">
                                            <img src="/img/DeparatureTimeIcons/2.png" alt="" />
                                            <p>10 AM to 5 PM</p>
                                        </div>
                                    </div>

                                    <div className="travelDetailSidebarDeparatureTimeBox">
                                        <div className="travelDetailSidebarDeparatureTimeItem">
                                            <img src="/img/DeparatureTimeIcons/3.png" alt="" />
                                            <p>5 PM to 11 PM</p>
                                        </div>

                                        <div className="travelDetailSidebarDeparatureTimeItem">
                                            <img src="/img/DeparatureTimeIcons/4.png" alt="" />
                                            <p>After 11 PM</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="travelDetailSidebarBusPartner">
                                    <div
                                        className="travelDetailSidebarBusPartnerHeader"
                                        style={{ marginBottom: busPartnerSec ? "16px" : "" }}
                                    >
                                        <p style={{ fontWeight: " 600", paddingLeft: "6px" }}>
                                            Bus Partner
                                        </p>
                                        <MdOutlineKeyboardDoubleArrowDown
                                            onClick={() => setBusPartnerSec(!busPartnerSec)}
                                            style={{
                                                transform: busPartnerSec ? "rotate(180deg)" : "",
                                            }}
                                        />
                                    </div>

                                    {busPartnerSec ? (
                                        <>
                                            <div className="travelDetailSidebarBusPartnerInput ">
                                                <div className="travelDetailSidebarBusPartnerInputDiv">
                                                    <input type="text" />
                                                </div>
                                                <div className="travelDetailSidebarBusPartnerInputSearchIcon">
                                                    <IoIosSearch
                                                        style={{ width: "20px", height: "20px" }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="travelDetailSidebarBusPartnerCheckBoxSec scrollbar">
                                                <div className="travelDetailSidebarBusPartnerCheckBox">
                                                    <input
                                                        type="checkbox"
                                                    // id="pricedrop"
                                                    // name="pricedrop"
                                                    // value="pricedrop"
                                                    />
                                                    <label htmlFor="pricedrop">Aditya Logistics</label>
                                                </div>

                                                <div className="travelDetailSidebarBusPartnerCheckBox">
                                                    <input
                                                        type="checkbox"
                                                    // id="pricedrop"
                                                    // name="pricedrop"
                                                    // value="pricedrop"
                                                    />
                                                    <label htmlFor="pricedrop">AVM Travels</label>
                                                </div>

                                                <div className="travelDetailSidebarBusPartnerCheckBox">
                                                    <input
                                                        type="checkbox"
                                                    // id="pricedrop"
                                                    // name="pricedrop"
                                                    // value="pricedrop"
                                                    />
                                                    <label htmlFor="pricedrop">Balaji Cabs</label>
                                                </div>

                                                <div className="travelDetailSidebarBusPartnerCheckBox">
                                                    <input
                                                        type="checkbox"
                                                    // id="pricedrop"
                                                    // name="pricedrop"
                                                    // value="pricedrop"
                                                    />
                                                    <label htmlFor="pricedrop">Bharathi Travels</label>
                                                </div>

                                                <div className="travelDetailSidebarBusPartnerCheckBox">
                                                    <input
                                                        type="checkbox"
                                                    // id="pricedrop"
                                                    // name="pricedrop"
                                                    // value="pricedrop"
                                                    />
                                                    <label htmlFor="pricedrop">BMCC Travels</label>
                                                </div>

                                                <div className="travelDetailSidebarBusPartnerCheckBox">
                                                    <input
                                                        type="checkbox"
                                                    // id="pricedrop"
                                                    // name="pricedrop"
                                                    // value="pricedrop"
                                                    />
                                                    <label htmlFor="pricedrop">BSR Travels Grand</label>
                                                </div>
                                            </div>
                                        </>
                                    ) : null}
                                </div>

                                <div className="travelDetailSidebarBusPartner">
                                    <div
                                        className="travelDetailSidebarBusPartnerHeader"
                                        style={{ marginBottom: boardingPoint ? "16px" : "" }}
                                    >
                                        <p style={{ fontWeight: " 600", paddingLeft: "6px" }}>
                                            Boarding Point
                                        </p>
                                        <MdOutlineKeyboardDoubleArrowDown
                                            onClick={() => setBoardingPoint(!boardingPoint)}
                                            style={{
                                                transform: boardingPoint ? "rotate(180deg)" : "",
                                            }}
                                        />
                                    </div>

                                    {boardingPoint ? (
                                        <>
                                            <div className="travelDetailSidebarBusPartnerInput">
                                                <div className="travelDetailSidebarBusPartnerInputDiv">
                                                    <input type="text" />
                                                </div>
                                                <div className="travelDetailSidebarBusPartnerInputSearchIcon">
                                                    <IoIosSearch
                                                        style={{ width: "20px", height: "20px" }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="travelDetailSidebarBusPartnerCheckBoxSec scrollbar">
                                                <div className="travelDetailSidebarBusPartnerCheckBox">
                                                    <input
                                                        type="checkbox"
                                                    // id="pricedrop"
                                                    // name="pricedrop"
                                                    // value="pricedrop"
                                                    />
                                                    <label htmlFor="pricedrop">Ameerpet</label>
                                                </div>

                                                <div className="travelDetailSidebarBusPartnerCheckBox">
                                                    <input
                                                        type="checkbox"
                                                    // id="pricedrop"
                                                    // name="pricedrop"
                                                    // value="pricedrop"
                                                    />
                                                    <label htmlFor="pricedrop">
                                                        Ameerpet Metro Station
                                                    </label>
                                                </div>

                                                <div className="travelDetailSidebarBusPartnerCheckBox">
                                                    <input
                                                        type="checkbox"
                                                    // id="pricedrop"
                                                    // name="pricedrop"
                                                    // value="pricedrop"
                                                    />
                                                    <label htmlFor="pricedrop">Anandbagh</label>
                                                </div>

                                                <div className="travelDetailSidebarBusPartnerCheckBox">
                                                    <input
                                                        type="checkbox"
                                                    // id="pricedrop"
                                                    // name="pricedrop"
                                                    // value="pricedrop"
                                                    />
                                                    <label htmlFor="pricedrop">Anantapur</label>
                                                </div>

                                                <div className="travelDetailSidebarBusPartnerCheckBox">
                                                    <input
                                                        type="checkbox"
                                                    // id="pricedrop"
                                                    // name="pricedrop"
                                                    // value="pricedrop"
                                                    />
                                                    <label htmlFor="pricedrop">Aramghar</label>
                                                </div>

                                                <div className="travelDetailSidebarBusPartnerCheckBox">
                                                    <input
                                                        type="checkbox"
                                                    // id="pricedrop"
                                                    // name="pricedrop"
                                                    // value="pricedrop"
                                                    />
                                                    <label htmlFor="pricedrop">Ashok Nagar</label>
                                                </div>
                                            </div>
                                        </>
                                    ) : null}
                                </div>

                                <div className="travelDetailSidebarBusPartner">
                                    <div
                                        className="travelDetailSidebarBusPartnerHeader"
                                        style={{ marginBottom: droppingPoint ? "16px" : "" }}
                                    >
                                        <p style={{ fontWeight: " 600", paddingLeft: "6px" }}>
                                            Dropping Point
                                        </p>
                                        <MdOutlineKeyboardDoubleArrowDown
                                            onClick={() => setDroppingPoint(!droppingPoint)}
                                            style={{
                                                transform: droppingPoint ? "rotate(180deg)" : "",
                                            }}
                                        />
                                    </div>

                                    {droppingPoint ? (
                                        <>
                                            <div className="travelDetailSidebarBusPartnerInput">
                                                <div className="travelDetailSidebarBusPartnerInputDiv">
                                                    <input type="text" />
                                                </div>
                                                <div className="travelDetailSidebarBusPartnerInputSearchIcon">
                                                    <IoIosSearch
                                                        style={{ width: "20px", height: "20px" }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="travelDetailSidebarBusPartnerCheckBoxSec scrollbar">
                                                <div className="travelDetailSidebarBusPartnerCheckBox">
                                                    <input
                                                        type="checkbox"
                                                    // id="pricedrop"
                                                    // name="pricedrop"
                                                    // value="pricedrop"
                                                    />
                                                    <label htmlFor="pricedrop">Ameerpet</label>
                                                </div>

                                                <div className="travelDetailSidebarBusPartnerCheckBox">
                                                    <input
                                                        type="checkbox"
                                                    // id="pricedrop"
                                                    // name="pricedrop"
                                                    // value="pricedrop"
                                                    />
                                                    <label htmlFor="pricedrop">
                                                        Ameerpet Metro Station
                                                    </label>
                                                </div>

                                                <div className="travelDetailSidebarBusPartnerCheckBox">
                                                    <input
                                                        type="checkbox"
                                                    // id="pricedrop"
                                                    // name="pricedrop"
                                                    // value="pricedrop"
                                                    />
                                                    <label htmlFor="pricedrop">Anandbagh</label>
                                                </div>

                                                <div className="travelDetailSidebarBusPartnerCheckBox">
                                                    <input
                                                        type="checkbox"
                                                    // id="pricedrop"
                                                    // name="pricedrop"
                                                    // value="pricedrop"
                                                    />
                                                    <label htmlFor="pricedrop">Anantapur</label>
                                                </div>

                                                <div className="travelDetailSidebarBusPartnerCheckBox">
                                                    <input
                                                        type="checkbox"
                                                    // id="pricedrop"
                                                    // name="pricedrop"
                                                    // value="pricedrop"
                                                    />
                                                    <label htmlFor="pricedrop">Aramghar</label>
                                                </div>

                                                <div className="travelDetailSidebarBusPartnerCheckBox">
                                                    <input
                                                        type="checkbox"
                                                    // id="pricedrop"
                                                    // name="pricedrop"
                                                    // value="pricedrop"
                                                    />
                                                    <label htmlFor="pricedrop">Ashok Nagar</label>
                                                </div>
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-9  p-0 ">
                            <div className="travelDetailContent">
                                <div className="travelDetailContentDateSec">{/* date */}</div>

                                <div className="travelDetailContentDetailsSec">
                                    <div className="travelDetailContentDetails">
                                        <div className="travelDetailContentDetailsBusDetail">
                                            <h5 className="d-flex">
                                                <span>
                                                    <FaBusAlt />
                                                </span>
                                                IntrCity SmartBus
                                            </h5>
                                            <p>Bharat Benz AC Sleeper (2 + 1)</p>
                                            <p>
                                                <span className="me-2">
                                                    <img src="/img/travelDetailPageIcons/1.png" alt="" />
                                                </span>
                                                Location Tracking
                                            </p>
                                        </div>

                                        <div className="travelDetailContentDetailsTimeSec">
                                            <div className="travelDetailContentDetailsTime">
                                                <h6>20:46</h6>
                                                <p>Hyderabad</p>
                                            </div>
                                            <div>
                                                <HiMiniArrowLongRight
                                                    style={{ fontSize: "20px", color: "blue" }}
                                                />
                                            </div>
                                            <div className="travelDetailContentDetailsHour">
                                                <p>12h:10m</p>
                                            </div>
                                            <div>
                                                <HiMiniArrowLongRight
                                                    style={{ fontSize: "20px", color: "blue" }}
                                                />
                                            </div>
                                            <div className="travelDetailContentDetailsTime">
                                                <h6>08:56</h6>
                                                <p>Bangalore</p>
                                            </div>
                                        </div>

                                        <div className="travelDetailContentDetailsPrice">
                                            <div className="travelDetailContentDetailsPriceAvlSeats">
                                                <p>17 seats available</p>
                                            </div>
                                            <div className="travelDetailContentDetailsPriceStartsAt">
                                                <p>starts at</p>
                                                <h2>&#8377; 1057</h2>
                                            </div>
                                            <div className="travelDetailContentDetailsPriceSelectBtn">
                                                <button onClick={() => setAmeneties(4)}>Select</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="travelDetailContentDetailsAmentiesSec">
                                        <div className="travelDetailContentDetailsAmentiesRating">
                                            <div className="travelDetailContentDetailsAmentiesRatingSec d-flex">
                                                <span className="travelDetailContentDetailsAmentiesRatingSecSp1">
                                                    4.6
                                                    <MdOutlineStar />
                                                </span>
                                                <span className="travelDetailContentDetailsAmentiesRatingSecSp2">
                                                    <AiOutlineUser />
                                                    7865
                                                </span>
                                            </div>
                                        </div>

                                        <div className="travelDetailContentDetailsAmentiesDet">
                                            <p>Amenities</p>
                                            {ameneties === 1 ? (
                                                <MdOutlineKeyboardDoubleArrowDown
                                                    onClick={() => setAmeneties(0)}
                                                    style={{ transform: "rotate(180deg)" }}
                                                />
                                            ) : (
                                                <MdOutlineKeyboardDoubleArrowDown
                                                    onClick={() => setAmeneties(1)}
                                                />
                                            )}
                                        </div>

                                        <div className="travelDetailContentDetailsAmentiesBoarding">
                                            <p>Boarding / Dropping Point</p>
                                            {ameneties === 2 ? (
                                                <MdOutlineKeyboardDoubleArrowDown
                                                    onClick={() => setAmeneties(0)}
                                                    style={{ transform: "rotate(180deg)" }}
                                                />
                                            ) : (
                                                <MdOutlineKeyboardDoubleArrowDown
                                                    onClick={() => setAmeneties(2)}
                                                />
                                            )}
                                        </div>

                                        <div className="travelDetailContentDetailsAmentiesCnlPolicy">
                                            <p>Cancellation Policy</p>
                                            {ameneties === 3 ? (
                                                <MdOutlineKeyboardDoubleArrowDown
                                                    onClick={() => setAmeneties(0)}
                                                    style={{ transform: "rotate(180deg)" }}
                                                />
                                            ) : (
                                                <MdOutlineKeyboardDoubleArrowDown
                                                    onClick={() => setAmeneties(3)}
                                                />
                                            )}
                                        </div>

                                        {/* <div className="travelDetailContentDetailsAmentiesBkngPolicy">
                                    <p>Booking Policy</p>
                                    {ameneties ===4?<MdOutlineKeyboardDoubleArrowDown onClick={()=>setAmeneties(0)} style={{transform:"rotate(180deg)"}}/>:<MdOutlineKeyboardDoubleArrowDown onClick={()=>setAmeneties(4)}/> }
                                   </div> */}
                                    </div>
                                    {ameneties === 1 && (
                                        <div className="travelDetailContentDetailsAmentiesSection">
                                            <h6>Amenities</h6>
                                            <div className="row">
                                                <div className="col-3">
                                                    <p>
                                                        <span>
                                                            <PiPlugLight />
                                                        </span>
                                                        Charging Point
                                                    </p>
                                                </div>
                                                <div className="col-3">
                                                    <p>
                                                        <span>
                                                            <GiCctvCamera />
                                                        </span>
                                                        CCTV
                                                    </p>
                                                </div>
                                                <div className="col-3">
                                                    <p>
                                                        <span>
                                                            <GiFlexibleLamp />
                                                        </span>
                                                        Reading Light
                                                    </p>
                                                </div>
                                                <div className="col-3">
                                                    <p>
                                                        <span>
                                                            <FaBottleWater />
                                                        </span>
                                                        Water Bottle
                                                    </p>
                                                </div>
                                                <div className="col-3">
                                                    <p>
                                                        <span>
                                                            <FaBottleWater />
                                                        </span>
                                                        Water Bottle
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {ameneties === 2 && (
                                        <div className="travelDetailContentDetailsAmentiesBoardingSection">
                                            <h6>Boarding / Dropping Point</h6>

                                            <div className="travelDetailContentDetailsAmentiesBoardingSectionDiv">
                                                <div className="travelDetailContentDetailsAmentiesBoardingSectionBoard">
                                                    <p className="travelDetailContentDetailsAmentiesBoardingSectionBoardHeading ">
                                                        Boarding Point
                                                    </p>
                                                    <div className="travelDetailContentDetailsAmentiesBoardingSectionBoardCont">
                                                        <div className="BoardingSectionBoardContDetail">
                                                            <p className="BoardingSectionBoardContDetailTime">
                                                                06:10 AM
                                                            </p>
                                                            <p className="BoardingSectionBoardContDetailPlace">
                                                                Afzalgunj (Van Pickup),Infront of Moti Travels
                                                                (Van Pickup) (Hyderabad),7303093510
                                                            </p>
                                                        </div>
                                                        <div className="BoardingSectionBoardContDetail">
                                                            <p className="BoardingSectionBoardContDetailTime">
                                                                07:10 AM
                                                            </p>
                                                            <p className="BoardingSectionBoardContDetailPlace">
                                                                Nampally,Shar Travels Near- Railway Station
                                                                (Hyderabad),7303093510
                                                            </p>
                                                        </div>
                                                        <div className="BoardingSectionBoardContDetail">
                                                            <p className="BoardingSectionBoardContDetailTime">
                                                                08:10 AM
                                                            </p>
                                                            <p className="BoardingSectionBoardContDetailPlace">
                                                                Madinaguda (Van Pickup),Opp. Bajaj Electronics
                                                                (Van Pickup) (Hyderabad),7303093510
                                                            </p>
                                                        </div>

                                                        <div className="BoardingSectionBoardContDetail">
                                                            <p className="BoardingSectionBoardContDetailTime">
                                                                09:10 AM
                                                            </p>
                                                            <p className="BoardingSectionBoardContDetailPlace">
                                                                Miyapur Alwin X Roads (Van Pickup),Opp. Agarwal
                                                                Eye Hospital (Van Pickup) (Hyderabad),7303093510
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="travelDetailContentDetailsAmentiesBoardingSectionDrop">
                                                    <p className="travelDetailContentDetailsAmentiesBoardingSectionBoardHeading">
                                                        Dropping Point
                                                    </p>
                                                    <div className="travelDetailContentDetailsAmentiesBoardingSectionBoardCont">
                                                        <div className="BoardingSectionBoardContDetail">
                                                            <p className="DroppingSectionBoardContDetailTime">
                                                                06:10 PM
                                                            </p>
                                                            <p className="DroppingSectionBoardContDetailDate">
                                                                17-oct
                                                            </p>
                                                            <p className="DroppingSectionBoardContDetailPlace">
                                                                Chikkaballapur,Bypass (Bangalore),Bypass
                                                                (Bangalore),7303093510
                                                            </p>
                                                        </div>

                                                        <div className="BoardingSectionBoardContDetail">
                                                            <p className="DroppingSectionBoardContDetailTime">
                                                                07:10 AM
                                                            </p>
                                                            <p className="DroppingSectionBoardContDetailDate">
                                                                17-oct
                                                            </p>
                                                            <p className="DroppingSectionBoardContDetailPlace">
                                                                Bial,Bial (Bangalore),Bial
                                                                (Bangalore),7303093510
                                                            </p>
                                                        </div>

                                                        <div className="BoardingSectionBoardContDetail">
                                                            <p className="DroppingSectionBoardContDetailTime">
                                                                08:10 AM
                                                            </p>
                                                            <p className="DroppingSectionBoardContDetailDate">
                                                                17-oct
                                                            </p>
                                                            <p className="DroppingSectionBoardContDetailPlace">
                                                                Bial,Bial (Bangalore),Bial
                                                                (Bangalore),7303093510
                                                            </p>
                                                        </div>

                                                        <div className="BoardingSectionBoardContDetail">
                                                            <p className="DroppingSectionBoardContDetailTime">
                                                                09:10 AM
                                                            </p>
                                                            <p className="DroppingSectionBoardContDetailDate">
                                                                17-oct
                                                            </p>
                                                            <p className="DroppingSectionBoardContDetailPlace">
                                                                Bial,Bial (Bangalore),Bial
                                                                (Bangalore),7303093510
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {ameneties === 3 && (
                                        <div className="travelDetailContentDetailsAmentiesCancelSection">
                                            <h6>Cancel Policy</h6>
                                            <div className="travelDetailContentDetailsAmentiesCancelSectionDiv">
                                                <div className="AmentiesCancelSectionDivHeader">
                                                    <p>Cancellation Time Before Departure</p>
                                                    <p>Amount Deduction(%)</p>
                                                </div>
                                                <div className="AmentiesCancelSectionDivitems">
                                                    <p style={{ fontSize: "14PX" }}>
                                                        Between 4 to 24 Hrs
                                                    </p>
                                                    <p style={{ fontWeight: "600" }}>50%</p>
                                                </div>
                                                <div className="AmentiesCancelSectionDivitems">
                                                    <p style={{ fontSize: "14PX" }}>Before 24 Hrs</p>
                                                    <p style={{ fontWeight: "600" }}>25%</p>
                                                </div>
                                                <div className="AmentiesCancelSectionDivitems">
                                                    <p style={{ fontSize: "14PX" }}>
                                                        Stop Cancellation Within 240 Minutes
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {ameneties === 4 && (
                                        <div>
                                            <div
                                                className=""
                                                style={{
                                                    width: "96%",
                                                    marginInline: "auto",
                                                    display: "flex",
                                                    justifyContent: "end",
                                                    color:"#342858",
                                                    fontSize:"25px"
                                                }}
                                            >
                                                <MdOutlineCancel onClick={() => setAmeneties(0)} />
                                            </div>

                                            <div className="travelDetailContentDetailsSelectSeatsDetails">
                                                <div className="travelDetailContentDetailsSelectSeatsSection">
                                                    <div className="travelDetailContentDetailsSelectSeatsLowerSection">
                                                        <div className="travelDetailContentDetailsSelectSeatsLowerHeading d-flex justify-content-around mt-4">
                                                            <p>Lower</p>
                                                            <img
                                                                src="/img/layout/steering-wheel-xxl.png"
                                                                alt=""
                                                                style={{
                                                                    width: "35px",
                                                                    transform: "rotate(270deg)",
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="travelDetailContentDetailsSelectSeatsLowerSectionHeader ">
                                                            <div className="travelDetailContentDetailsSelectSeatsLowerSectionHeaderLft">
                                                                <table>
                                                                    <tr>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_avail.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr></tr>
                                                                    <tr>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_booked.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr></tr>
                                                                    <tr>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_booked.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr></tr>
                                                                    <tr>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_booked.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr></tr>
                                                                    <tr>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_booked.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr></tr>
                                                                    <tr>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_avail.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </div>

                                                            <div className="travelDetailContentDetailsSelectSeatsLowerSectionHeaderRgt">
                                                                {/* <img src="/img/layout/steering-wheel-xxl.png" alt="" style={{ width: "35px", transform: "rotate(270deg)" }} /> */}
                                                                <table>
                                                                    <tr>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_avail.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_avail.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr></tr>
                                                                    <tr>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_avail.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_avail.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr></tr>
                                                                    <tr>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_booked_ladies.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_booked_ladies.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr></tr>
                                                                    <tr>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_booked_ladies.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_booked_ladies.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr></tr>
                                                                    <tr>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_avail.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_avail.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr></tr>
                                                                    <tr>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_avail.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_avail.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr></tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="travelDetailContentDetailsSelectSeatsUpperSection">
                                                        <div className="travelDetailContentDetailsSelectSeatsLowerHeading d-flex justify-content-around mt-4">
                                                            <p>Upper</p>
                                                        </div>

                                                        <div className="travelDetailContentDetailsSelectSeatsLowerSectionHeader ">
                                                            <div className="travelDetailContentDetailsSelectSeatsLowerSectionHeaderLft">
                                                                <table>
                                                                    <tr>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_avail.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr></tr>
                                                                    <tr>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_avail.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr></tr>
                                                                    <tr>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_avail.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr></tr>
                                                                    <tr>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_avail.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr></tr>
                                                                    <tr>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_avail.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr></tr>
                                                                    <tr>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_avail.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </div>

                                                            <div className="travelDetailContentDetailsSelectSeatsLowerSectionHeaderRgt">
                                                                {/* <img src="/img/layout/steering-wheel-xxl.png" alt="" style={{ width: "35px", transform: "rotate(270deg)" }} /> */}
                                                                <table>
                                                                    <tr>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_avail.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_avail.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr></tr>
                                                                    <tr>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_avail_ladies.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_avail_ladies.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr></tr>
                                                                    <tr>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_avail_ladies.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_avail_ladies.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr></tr>
                                                                    <tr>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_avail_ladies.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_avail_ladies.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr></tr>
                                                                    <tr>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_avail.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_avail.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr></tr>
                                                                    <tr>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_avail.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                        <td
                                                                            rowspan="2"
                                                                            id=""
                                                                            className=" td_slepeer"
                                                                        >
                                                                            <img
                                                                                src="/img/layout/hor_slepeer_avail.png"
                                                                                id=""
                                                                                className="hor-img-sleeper"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr></tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="travelDetailContentDetailsSelectPriceSection">
                                                       <div class="offer-container3">
                                                            <div class="seat-indicator">
                                                                <div class="seat01 "><em></em>Available Seats</div>
                                                                <div class="seat01 avialable-ladies"><em></em>Avialable For Women</div>
                                                                <div class="seat01 elected "><em></em>Selected</div>
                                                                <div class="seat01 booked"><em></em>Booked</div>
                                                                <div class="seat01 booked-ladies"><em></em>Booked By Women</div>
                                                            </div>

                                                            <div class="text-center ">
                                                                <div class="info-imp-1">
                                                                    The seats are indicative and not guaranteed. The bus operator reserves the right to alter them.
                                                                </div>
                                                            
                                                                <div class="info-imp-2">
                                                                    Male cannot book the seat booked for ladies.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="offer-container margin-top-20px">
                                                            <div class="margin-bottom-10px ">
                                                                <span class="sky-label"> Seat Selected : </span> 
                                                                <span class="sky-value" id="txtSeatNos-NXS_159_1071_1003666_1001968_20241019">LHSL6,</span>
                                                            </div>
                                                            <div class="sky-line mar-0"></div>
                                                            <div class=" margin-top-5px">
                                                                <span class="sky-label sky-label-grey"> Base Fare : </span> 
                                                                <span class="sky-sub-title-3 pull-right" id="txtBaseFare-NXS_159_1071_1003666_1001968_20241019">1500.00</span>
                                                            </div>
                                                            <div class=" margin-top-5px">
                                                                <span class="sky-label sky-label-grey"> GST : </span> 
                                                                <span class="sky-sub-title-3 pull-right" id="txtStax-NXS_159_1071_1003666_1001968_20241019">75.00</span>
                                                            </div> 
                                                            <div class=" margin-top-5px">
                                                                <span class="sky-label sky-label-grey">Operator Service Charge : </span> 
                                                                <span class="sky-sub-title-3 pull-right" id="txtOtax-NXS_159_1071_1003666_1001968_20241019">0.00</span>
                                                            </div>
                                                            <div class="sky-line mar-0"></div>
                                                            <div class=" margin-top-10px">
                                                                <span class="sky-label sky-sub-title-3 "> Total Fare : </span> 
                                                                <span class="sky-sub-title-1 pull-right" id="txtTotal-NXS_159_1071_1003666_1001968_20241019">1575.00</span>
                                                            </div>
                                                        </div>
                                                        <div class=" margin-top-20px">
                                                                <button type="button" class="btn btn-primary sky-pay" >Continue</button>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                           
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TravelDetails;
