import React, { useRef, useState } from 'react'
import { FaPersonHiking, FaPersonWalkingLuggage, FaPersonWalking, FaArrowRightArrowLeft } from "react-icons/fa6";
import { FaRegCalendarAlt } from "react-icons/fa";
import { IoBusOutline } from "react-icons/io5";
import { FaLongArrowAltRight, FaLongArrowAltLeft } from "react-icons/fa";
import './home.css';
import Pcalendar from './Calendar/Pcalendar';
import { useSwiper } from 'swiper/react';
import { Link, useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide, loop } from 'swiper/react';
import { IoMdCheckmark, IoIosStar, IoIosStarHalf } from "react-icons/io";
import { FaRoute, FaShieldAlt, FaBusAlt, FaRupeeSign } from "react-icons/fa";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/bundle';



// import required modules
import { Pagination, Autoplay, Navigation, } from 'swiper/modules';

const Home = () => {

  const [changSection, setChangeSection] = useState(true)
  const [calendar, setCalendar] = useState(false)
  const navigate = useNavigate()






  const swiper = useSwiper();


  const OfferSection = [

    {
      offerImg :"/img/offer/image-1.png",
      offerCode :"Code",
      offerName :"ONBUS",
      offerDetail:"View Detail",
    },

    {
      offerImg :"/img/offer/image-2.png",
      offerCode :"Code",
      offerName :"HOLYBUS",
      offerDetail:"View Detail",
    },
    {
      offerImg :"/img/offer/image-3.png",
      offerCode :"Code",
      offerName :"FIRSTBUS",
      offerDetail:"View Detail",
    },

    {
      offerImg :"/img/offer/image-1.png",
      offerCode :"Code",
      offerName :"ONBUS",
      offerDetail:"View Detail",
    },

    {
      offerImg :"/img/offer/image-2.png",
      offerCode :"Code",
      offerName :"HOLYBUS",
      offerDetail:"View Detail",
    },
    {
      offerImg :"/img/offer/image-3.png",
      offerCode :"Code",
      offerName :"FIRSTBUS",
      offerDetail:"View Detail",
    },

  ]




  const whyChooseSection = [
    {
      id: 1,
      icon: <FaShieldAlt />,
      heading: "100%",
      desc: "SAFETY",
    },

    {
      id: 2,
      icon: <FaRupeeSign />,
      heading: "Travel at",
      desc: "LOWEST PRICE",
    },

    {
      id: 3,
      icon: <FaRoute />,
      heading: "1,00,000 +",
      desc: "ROUTES AVAILABLE",
    },

    {
      id: 4,
      icon: <FaBusAlt />,
      heading: "2,500 +",
      desc: "BUS PARTNERS",
    },
  ];


  return (
    <>
      <div className='d-flex align-items-start flex-column ' style={{ background:"url('/images/pencilsketch.png')",position:"relative",objectFit:"cover",WebkitBackgroundSize:"cover",minHeight:"400px",backgroundPosition:"center",backgroundSize:"cover", backgroundRepeat:"no-repeat",width:"100%",borderBottom:"3px solid #ccc",display:"flex",flexDirection:"column", justifyContent:"space-between"}}>
        <div className="container container_search_bar mt-5 bg-white" style={{ borderRadius: "5px", padding: "20px" ,position:"relative",marginBottom:"10px"}}>
          <div className="row search_bar gap-3  justify-content-md-evenly align-items-center position-relative">
            <div className="col-lg-3 search_col ">
              {
              changSection ?
                <><div className='search_bar_icon'>
                  <IoBusOutline className="bus-icon" style={{}} />
                  <FaPersonWalkingLuggage style={{ transform: "scaleX(-1)" }} className='person person1' />
                </div>
                  <div className='search_bar_input'>
                    <div className="form-floating">
                      <input type="text" className="form-control" id="floatingInput" placeholder="" style={{cursor:"pointer"}} />
                      <label>From</label>
                    </div>
                  </div></> :
                <><div className='search_bar_icon'>
                  <IoBusOutline className="bus-icon" style={{}} />
                  <FaPersonWalkingLuggage className='person' />
                </div>
                  <div className='search_bar_input'>
                    <div className="form-floating">
                      <input type="text" className="form-control" id="floatingInput" placeholder="" style={{ outline: "none" ,cursor:"pointer"}} />
                      <label >To</label>
                    </div>
                  </div> </>
              }

            </div>

            <div className={`col-lg-1 border ${changSection ? "search_bar_arrow" : "search_bar_arrow1"} `} >
              <FaArrowRightArrowLeft onClick={(() => setChangeSection(!changSection))} />
            </div>

            <div className="col-lg-3 search_col ">

              {changSection ? <><div className='search_bar_icon'>
                <IoBusOutline className="bus-icon" style={{}} />
                <FaPersonWalkingLuggage className='person' />
              </div>
                <div className='search_bar_input'>
                  <div className="form-floating">
                    <input type="text" className="form-control" id="floatingInput" placeholder="" style={{ outline: "none" ,cursor:"pointer"}} />
                    <label >To</label>
                  </div>
                </div> </> :
                <><div className='search_bar_icon'>
                  <IoBusOutline className="bus-icon" style={{}} />
                  <FaPersonWalkingLuggage style={{ transform: "scaleX(-1)" }} className='person person1' />
                </div>
                  <div className='search_bar_input'>
                    <div className="form-floating">
                      <input type="text" className="form-control" id="floatingInput" placeholder="" style={{cursor:"pointer"}} />
                      <label >From</label>
                    </div>
                  </div></>}

            </div>
            <div className="col-lg-2 search_col ">
              <div className='search_bar_icon'>
                <FaRegCalendarAlt style={{ height: "28px", width: "28px", color: "#324580" }} />
              </div>
              <div className='search_bar_date' onClick={() => setCalendar(!calendar)}>
                <h6 className='text-center'>Date</h6>

              </div>
            </div>
            <div className="col-lg-2 search_col  ">
              <button className='fs-5' onClick={()=>navigate("/travel_details")}>Search</button>
            </div>
          </div>
        </div>
        {calendar ?
          <div className='' style={{ position: "relative", top: "-4%", right: "auto", bottom: "auto", left: "60%" }}>
            <Pcalendar />
          </div> : null}

          <div  className="" style={{position:"relative", height:"20%",width:"100%", bottom:"0px",marginTop:""}} >

          <div className="bus_shape">
          
        <img src="/images/bus.png" alt="" width="200px"  className=""/>

        </div>

        </div>

      </div>


     


         {/* ///////////////////////////////////////////////// offer slider section   ///////////////////////////////////////////////// */}


      <div className='container  offer_container ' style={{}} >

              <div><h2 className="text-center fw-bold" style={{color:"#883778"}}>Amazing Offer</h2></div>

        <div style={{ padding:"" }}>


          <Swiper
            style={{ padding:"8px"

            }}
            slidesPerView={1}
            spaceBetween={10}
            loop={true}
            // autoplay={true}
            pagination={{ el: '.swiper-pagination', clickable: true }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
              clickable: true,

            }}
            breakpoints={{

              // 480: {
              //   slidesPerView: 2,
              // },

              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            modules={[Pagination, Autoplay, Navigation]}
            className="swiper_container "
          >
  {
        OfferSection.map((val,ind)=>(   
        <SwiperSlide className='swiper_slide '>

              <div class="offerImageSection">
                <img src={val.offerImg} alt="" />
              </div>

              <div style={{}} className=''>

                <div className='offerSectionDetails' style={{ padding: "" }}>
                  <p ><span className='offer_code'>CODE :</span><span className='offer_name'>ONBUS</span></p>
                  <p className='offer_details'>View Details</p>
                </div>
              </div>
            </SwiperSlide>
            )) 
            }



            {/* <SwiperSlide className='swiper_slide'>

              <div>
                <img src="/Images1/slider Images/1.png" alt="" />
              </div>

              <div style={{ display: "flex", justifyContent: "space-between", padding: "5px 5px" }}>

                <div className='' style={{ padding: "" }}>
                  <span className='offer_code'>CODE :</span>
                  <span className='offer_name'> HOLYBUS</span>
                </div>

                <div>


                </div>
              </div>
            </SwiperSlide> */}

            {/* <SwiperSlide className='swiper_slide'>

              <div>
                <img src="/Images1/slider Images/1.png" alt="" />
              </div>

              <div style={{ display: "flex", justifyContent: "space-between", padding: "5px 5px" }}>

                <div className='' style={{ padding: "" }}>
                  <span className='offer_code'>CODE :</span>
                  <span className='offer_name'> FIRSTBUS</span>
                </div>

                <div>


                </div>
              </div>
            </SwiperSlide> */}
{/* 
            <SwiperSlide className='swiper_slide'>

              <div>
                <img src="/Images1/slider Images/1.png" alt="" />
              </div>

              <div style={{ display: "flex", justifyContent: "space-between", padding: "5px 5px" }}>

                <div className='' style={{ padding: "" }}>
                  <span className='offer_code'>CODE :</span>
                  <span className='offer_name'> ONBUS</span>
                </div>

                <div>


                </div>
              </div>
            </SwiperSlide> */}


{/* 
            <SwiperSlide className='swiper_slide'>

              <div>
                <img src="/Images1/slider Images/1.png" alt="" />
              </div>

              <div style={{ display: "flex", justifyContent: "space-between", padding: "5px 5px" }}>

                <div className='' style={{ padding: "" }}>
                  <span className='offer_code'>CODE :</span>
                  <span className='offer_name'> HOLYBUS</span>
                </div>

                <div>


                </div>
              </div>
            </SwiperSlide> */}

            {/* <SwiperSlide className='swiper_slide'>

              <div>
                <img src="/Images1/slider Images/1.png" alt="" />
              </div>

              <div style={{ display: "flex", justifyContent: "space-between", padding: "5px 5px" }}>

                <div className='' style={{ padding: "" }}>
                  <span className='offer_code'>CODE :</span>
                  <span className='offer_name'> FIRSTBUS</span>
                </div>

                <div>


                </div>
              </div>
            </SwiperSlide> */}



          </Swiper>

        </div>


        <div style={{ display: "flex", justifyContent: "center",margin:"8px 6px",  }}>

          <div className='flex  mt-[15px]  h-[50px]' style={{ border: "", alignItems: "center", }} >



            <div className="flex  slider-controler" style={{ gap: "" }}>

              <div >

                <div className="swiper-button-prev slider-arrow" style={{ border: "", }}></div>

                <div style={{ border: "" }}><FaLongArrowAltLeft style={{ width: "25px", height: "25px", color: "#a03d3d", border: "1px solid white", borderRadius: "50%", padding: "4px", cursor: "pointer",boxShadow:"2px 2px 4px #683d3d",background:"white"  }} /></div>



              </div>

              <div className="swiper-pagination"></div>

              <div className="" style={{ border: "" }}>

                <div className="swiper-button-next slider-arrow" style={{ border: "", }}></div>

                <div><FaLongArrowAltRight style={{ width: "25px", height: "25px", color: "#a03d3d", borderRadius: "50%", padding: "4px", cursor: "pointer",boxShadow:"2px 2px 4px #683d3d",background:"white" }} /></div>


              </div>

            </div>


          </div>

        </div>



      </div>


{/* ///////////////////////////////////////////////// auto sliding cities section  /////////////////////////////////////////////////// */}

      <div className="city_wrapper d-flex align-items-center " style={{ background: "rgb(77 35 180)", marginBottom: "50px" }}>

        <div className="item item1">MUMBAI</div>
        <div className="item item2">BANGALORE</div>
        <div className="item item3">HYDERABAD</div>
        <div className="item item4">CHENNAI</div>
        <div className="item item5">JAIPUR</div>
        <div className="item item6">MYSORE</div>
        <div className="item item7">SURAT</div>
        <div className="item item8">KOLKATTA</div>
        <div className="item item9">KOLHAPUR</div>
        <div className="item item10">TIRUPATHI</div>

      </div>




{/* ////////////////////////////////////////////////////////////// why choose us section ////////////////////////////////////////////////// */}
      
      <div>
        <div className='container whyChoseUsContainer  mt-5' style={{ marginBottom: "50px" }}>

          <div className='row  whyChoseUsRow '>
            {
              whyChooseSection.map((val, ind) =>
              (
                <>
                  <div className='col-lg-3  whyChoseUsSection  justify-content-center p-2' style={{}}>
                    <div className=' whyChoseUsItem'>
                      <div className='whyChoseUsIconDiv'>
                      <div className='whyChoseUsIcon'>{val.icon}</div>
                      </div>

                      <div className='whyChoseUsDetail'>
                        <p className='whyChoseUsDetailHeading'>{val.heading}</p>
                        <div className='whyChoseUsDetailDesc'>{val.desc}</div>
                      </div>

                    </div>
                  </div>
                </>
              ))
            }
          </div>

        </div>
      </div>



    {/* ///////////////////////////////////////// playstore section   ///////////////////////////////////////////////////////////////// */}

      <div className="container  playstore_main " style={{ borderRadius: "15px", padding: "30px", position: "relative", background: "#e7e6ee", boxShadow: "2px 2px 8px #483214", marginBottom: "400px" }}>

        <div className="  ply_store" style={{}}>


          <div className="ply_div1" >
            {/* <h3 className="text-red-500" style={{marginBottom:"20px"}}>Quick acces</h3> */}
            <img src="/images/mobile.png" alt="" style={{ width: "220px", height: "260px", position: "relative", rotate: "30deg" }} />
          </div>

          <div className="ply_div2 d-flex justify-content-center align-items-center flex-column"  >

            <div className="" style={{ height: "60%", backgroundColor: "white", padding: "12px", borderRadius: "10px", boxShadow: " 2px 2px 4px #e4d3d3" }}>

              <div className="ply_dnw">

                <ul className='' style={{ padding: "0" }}>

                  <li><span><FaLongArrowAltRight className="icon" /></span>Quick Access</li>
                  <li><span><FaLongArrowAltRight className="icon" /></span>Live Tracking</li>

                </ul>

              </div>



              <h3 className="text-[22px] text-center font-bold mt-4 mb ply_div2_heading  ">Rating</h3>

              <div className="ply_icons  d-flex  justify-content-center" style={{}}>
                <span><IoIosStar /></span>
                <span><IoIosStar /></span>
                <span><IoIosStar /></span>
                <span><IoIosStar /></span>
                <span><IoIosStarHalf /></span>
              </div>

            </div>

          </div>

          <div className="ply_div3" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

            <h3 className=" text-[28px] text-center playstore_heading">Make Your Journey Special <br /> With Bookurself App</h3>
            {/* 
          <img src="/images/1.png" alt="" style={{width:"120px"}} /> */}


            <div className="playstore_spl mt-5">
              <ul >
                <li><span><IoMdCheckmark className="icon" /></span>Get Notified</li>
                <li ><span><IoMdCheckmark className="icon" /></span>Trusted By Millions</li>
                <li><span><IoMdCheckmark className="icon" /></span>Enjoy Other Offers</li>
              </ul>
            </div>

            <div className="playstore_image mb-4" style={{ width: "", display: "flex", justifyContent: "center", marginTop: "10px" }}>

              <img src="/images/1.png" alt="" style={{ width: "120px", }} />

              {/* <img src="/images/2.png" alt="" style={{ width: "120px", }} /> */}

            </div>



          </div>



        </div>

      </div>

{/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

    </>
  )
}

export default Home