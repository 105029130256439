import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";
import "./header.css";

const Header = () => {
  const [LoginModal, SetLoginModal] = useState(0)
  const navigate = useNavigate()
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar navbar-dark bg-dark">
        <div className="container-fluid">
          <Link className="navbar-brand" href="#">
            Navbar
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  to="/"
                  aria-current="page"
                  href="#"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  to="/about"
                  aria-current="page"
                  href="#"
                >
                  About
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link active"
                  to="/privacy"
                  aria-current="page"
                  href="#"
                >
                  Privacy Policy
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  to="/faq"
                  aria-current="page"
                  href="#"
                >
                  Faq
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link active"
                  to="/terms"
                  aria-current="page"
                  href="#"
                >
                  T & C
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link active"
                  to="/contact"
                  aria-current="page"
                  href="#"
                >
                  Contact Us
                </Link>
              </li>
            </ul>
            <form className="d-flex">
            <li
                className="nav-link active text-light me-5"
                to=""
                aria-current="page"
                onClick={()=>navigate("/profile")}
              >
                Profile
              </li>

              <li
                className="nav-link active text-light me-5"
                to=""
                aria-current="page"
                onClick={()=>SetLoginModal(1)}
              >
                Login
              </li>
            </form>
          </div>
        </div>
      </nav>

{/* ///////////////////////////////////////////////////// Login Modal /////////////////////////////////////////////////////// */}

    { LoginModal === 1 && <div className="LoginPage">
        <div className="LoginPageSection mt-4">

        <div className="LoginPageCancel">
            <MdOutlineCancel onClick={()=>SetLoginModal(0)} />
          </div>

          <div className="LoginPageSectionHeader">
            <p className="" style={{cursor:"crosshair"}}>SignUp With Bookurself</p>
          </div>

          <div className="LoginPageSectionInputSection">
            <div className="LoginPageSectionInputItem">
              <label htmlFor="">Email</label>
              <input type="text" placeholder="Email" style={{cursor:"pointer"}} />
            </div>
            <div className="LoginPageSectionInputItem"> 
              <label htmlFor="">Password</label>
              <input type="password" placeholder="Password" style={{cursor:"pointer"}} />
            </div>
            </div>


            <div className="LoginPageSectionBottom">
              <p style={{color:"#bd6b6b",cursor:"pointer"}} onClick={()=>SetLoginModal(3)} >Forgot Password?</p>
              <p style={{color:"#3e3eb1",cursor:"pointer"}} onClick={()=>SetLoginModal(2)}>Create Account</p>
            </div>

            <div className="LoginPageSectionBtnSec">
              <button className="LoginPageSectionLoginBtn">Sign In</button>
            </div>
          
            <div className="socialmedia-login">
                <p><span className="text-center" style={{fontSize:"14px",fontWeight:"500",color:"#ab513b"}}>Or Login With</span></p>
            </div>
            
            <div className="LoginPageSectionBtnSec1">
            <div className="LoginPageSectionBtnIcon">
            <img src="/img/Google Icon/1.png" alt="" />
            </div>
              <button className="LoginPageSectionGoogleBtn">
                 Sign In Google</button>
            </div>

           
         

          
        </div>
      </div>}


      {/* ///////////////////////////////////////////////////// Create Account Modal /////////////////////////////////////////////////////// */}

      { LoginModal === 2 &&  <div className="LoginPage CreateAccountPage">
        <div className="LoginPageSection CreateAccountSection mt-4">

        <div className="LoginPageCancel">
            <MdOutlineCancel onClick={()=>SetLoginModal(0)}/>
          </div>

          <div className="LoginPageSectionHeader">
            <p className="">Create Your Account</p>
          </div>

         <div className="row  CreateAccountSectionRow">
          <div className="col-6 CreateAccountSectionCol">
          <label htmlFor="">FirstName</label>
            <input type="text" className="CreateAccountSectionColInp"  placeholder="FirstName"  style={{cursor:"pointer"}} />
          </div>
          <div className="col-6 CreateAccountSectionCol">
          <label htmlFor="">LastName</label>
            <input type="text" className="CreateAccountSectionColInp"  placeholder="LastName" style={{cursor:"pointer"}}  />
          </div>
         </div>

         <div className="row  CreateAccountSectionRow">
          <div className="col-6 CreateAccountSectionCol">
          <label htmlFor="">Mobile No</label>
            <input type="text" className="CreateAccountSectionColInp"  placeholder="Mobile No"  style={{cursor:"pointer"}} />
          </div>
          <div className="col-6 CreateAccountSectionCol">
          <label htmlFor="">Email Id</label>
            <input type="text"  className="CreateAccountSectionColInp" placeholder="Email ID" style={{cursor:"pointer"}} />
          </div>
         </div>

         <div className="row  CreateAccountSectionRow">
          <div className="col-6 CreateAccountSectionCol">
          <label htmlFor="">Password</label>
            <input type="text" className="CreateAccountSectionColInp"  placeholder="Password" style={{cursor:"pointer"}} />
          </div>
          <div className="col-6 CreateAccountSectionCol">
          <label htmlFor="">Refferal Code</label>
            <input type="text" className="CreateAccountSectionColInp"  placeholder="Refferal Code" style={{cursor:"pointer"}}  />
          </div>
         </div>

     



         

            <div className="LoginPageSectionBtnSec">
              <button className="LoginPageSectionLoginBtn">Sign Up</button>
            </div>
          

          <div className="mt-2">
            <p>Already have an account? Click here to<Link className="ms-2" onClick={()=>SetLoginModal(1)}>Sign In</Link></p>
          </div>
         
            
           
         

          
        </div>
      </div>}



      {/* ///////////////////////////////////////////////////// Forgot Password Modal /////////////////////////////////////////////////////// */}


      { LoginModal === 3 &&  <div className="LoginPage ForgotPasswordPage">
        <div className="LoginPageSection ForgotPasswordSection mt-4">

        <div className="LoginPageCancel">
            <MdOutlineCancel onClick={()=>SetLoginModal(0)}/>
          </div>

          <div className="LoginPageSectionHeader">
            <p className="" >Forgot Password</p>
          </div>

         <div className="row  ForgotPassSectionRow">
          <div className="col-12 ForgotPassSectionCol">
          <label htmlFor="" className="mb-2">Email/Mobile No</label>
            <input type="text" className="ForgotPassSectionColInp"  placeholder="Email/Mobile" />
          </div>
       
         </div>

    
            <div className="LoginPageSectionBtnSec">
              <button className="LoginPageSectionLoginBtn" onClick={()=>SetLoginModal(4)}>Next</button>
            </div>
          

         
            
           
         

          
        </div>
      </div>}

      {/* ///////////////////////////////////////////////// Forgot Password OTP modal /////////////////////////////////////////////////// */}

      { LoginModal === 4 &&   <div className="LoginPage ForgotPasswordPage">
        <div className="LoginPageSection ForgotPasswordSection mt-4">

          <div className="LoginPageCancel">
            <MdOutlineCancel onClick={()=>SetLoginModal(0)}/>
          </div>

          <div className="LoginPageSectionHeader">
            <p className="">Verify Code</p>
          </div>

         <div className="row  ForgotPassSectionRow">
          <div className="col-12 ForgotPassSectionCol">
          <label htmlFor="" className="mb-2">Enter OTP</label>
            <input type="text" className="ForgotPassSectionColInp"  placeholder="Code" />
          </div>
       
         </div>

    
            <div className="LoginPageSectionBtnSec">
              <button className="LoginPageSectionLoginBtn" onClick={()=>SetLoginModal(5)}>Next</button>
            </div>
          

         
            
           
         

          
        </div>
      </div>}
      

        {/* ///////////////////////////////////////////////// New Password modal /////////////////////////////////////////////////// */}

        { LoginModal === 5 &&  <div className="LoginPage ForgotPasswordPage">
        <div className="LoginPageSection ForgotPasswordSection mt-4">

        <div className="LoginPageCancel">
            <MdOutlineCancel onClick={()=>SetLoginModal(0)}/>
          </div>

          <div className="LoginPageSectionHeader">
            <p className="">Create New Password</p>
          </div>

         <div className="row  ForgotPassSectionRow">
          <div className="col-12 ForgotPassSectionCol">
          <label htmlFor="" className="mb-1">New Password</label>
            <input type="password" className="ForgotPassSectionColInp"  placeholder="New Password" />
          </div>

          <div className="col-12 ForgotPassSectionCol mt-3">
          <label htmlFor="" className="mb-1">Confirm Password</label>
            <input type="password" className="ForgotPassSectionColInp"  placeholder="Confirm Password" />
          </div>
       
         </div>

    
            <div className="LoginPageSectionBtnSec">
              <button className="LoginPageSectionLoginBtn">Submit</button>
            </div>
          

         
            
           
         

          
        </div>
      </div>}

    </>
  );
};

export default Header;
