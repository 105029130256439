import React, { useState } from 'react'
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import './privacypolicy.css'

const PrivacyPolicy = () => {

    const [accordion, setAccordion] = useState(0);
    

    return (
        <>
            <section className=" mt-4">
                <div className="container">
                    <div className="row ">
                        <h4 className=""> Privacy Policy </h4>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
                            <div className="">
                                <div className="accordion-section">
                                    <div className="accordion-header" style={{}}>
                                        <h5>Pivacy Notice</h5>
                                        <div className="me-4" >

                                            {

                                                accordion ===1 ? <IoIosEyeOff className='acc-icon' onClick={() => setAccordion(0)} /> : <IoIosEye className='acc-icon1' onClick={() => setAccordion(1)} />

                                            }


                                        </div>
                                    </div>
                                    {accordion === 1 && <div className="accordion-content">
                                        <p className="" >As a responsible data controller and data processor, BookUrSelf recognizes the importance of your privacy and the importance of maintaining the confidentiality of the information you provide.</p>
                                        <p className="" >This Privacy Policy provides for the practices for handling and securing user's Personal Information (defined hereunder) by BookUrSelf and its subsidiaries and affiliates.</p>
                                        <p className="" > This Privacy Policy is applicable to any person (‘User’) who purchases, intends to purchase, or inquire about any product(s) or service(s) made available by BookUrSelf through any of BookUrSelf’s customer interface channels including its website, mobile site, mobile app &amp; offline channels including call centers and offices (collectively referred herein as "Sales Channels").</p>
                                        <p className="" >Wherever the context requires, for the purposes of this Privacy Policy, "you" or "your" means "User" and the terms "we", "us" and "our" refer to BookUrSelf means For the purposes of this Privacy Policy, Website means Website, Mobile Website and Mobile App.</p>
                                        <p className="" >BY USING OR ACCESSING THE WEBSITE OR OTHER SELLING CHANNEL, YOU AGREE TO THE TERMS AND CONDITIONS OF THIS PRIVACY POLICY. If you do not agree to this Privacy Policy, please do not use or access our website or other distribution channels. </p>
                                        <p className="" >This privacy policy does not apply to his websites, mobile websites and mobile apps of third parties. Even if those websites/products are linked to our website. Users should be aware that the information and privacy practices of BookUrSelf' business partners, advertisers, sponsors or other websites to which BookUrSelf provides hyperlinks may differ materially from this Privacy Policy. Therefore, we encourage you to review the privacy statements and policies of any such third parties</p>
                                        <p className="">This Privacy Policy forms an integral part of the User Agreement with BookUrSelf and all capitalized terms used herein shall have their respective meanings as defined in the User Agreement.</p>
                                    </div> }
                                </div>
                                <div className="accordion-section">
                                    <div className="accordion-header">
                                        <h5>Users Outside The Geographical Limits Of India</h5>
                                        <div className="arrow me-4" >

                                            {

                                                accordion === 2 ? <IoIosEyeOff className='acc-icon' onClick={() => setAccordion(0)} /> : <IoIosEye className='acc-icon1' onClick={() => setAccordion(2)} />

                                            }
                                        </div>
                                    </div>
                                    {accordion === 2 && <div className="accordion-content">
                                        <p className="" >Please note that data shared with BookUrSelf is primarily processed in India and other jurisdictions where third parties entrusted by BookUrSelf may process data on behalf of BookUrSelf. By agreeing to this policy, you expressly consent to the processing of your personal data for the purposes defined in this policy. The data protection rules in India or other jurisdictions mentioned above may differ from those in your country of residence.</p>
                                        <p className="" > If you have concerns about the processing of your data and wish to withdraw your consent, you may notify us in writing to the email address privacy@BookUrSelf.com. However, if the processing of such data is essential for us to be able to provide you with services, we may not be able to provide the services or confirm your booking after you withdraw your consent. You may not be able to. For example, if you book a bus ride, we may need to share certain personal information with the bus operator, such as your contact details, gender and location. Bus operators should process this information further to make appropriate travel arrangements.</p>
                                        <p className="" >A withdrawal of consent by you for us to process your information may:</p>
                                        <p className="" >1. Severely inhibit our ability to serve you properly and in such case, we may have to refuse the booking altogether, or</p>
                                        <p className="" >2. Unreasonably restrict us to service your booking (if a booking is already made) which may further affect your trip or may compel us to cancel your booking.</p>
                                        <p></p>
                                    </div> }
                                </div>
                                <div className='accordion-section'>
                                    <div className="accordion-header">
                                        <h5>Types Of Information We Collect And Legal Basics</h5>
                                        <div className="me-4 " >

                                            {

                                                accordion === 3 ? <IoIosEyeOff className='acc-icon' onClick={() => setAccordion(0)} /> : <IoIosEye className='acc-icon1' onClick={() => setAccordion(3)} />

                                            }


                                        </div>
                                    </div>
                                    {accordion === 3 && <div className="accordion-content">
                                        <p className="" >The information described below is collected to allow us to provide you with the services you have selected and to comply with your legal and third party obligations under your User Agreement. A User's </p>
                                        <p className="" >"Personal Information" includes information provided by the User and collected by the Company for the following purposes:</p>
                                        <p className="" >1. Website Registration: Information you provide when subscribing or registering for the Website. This includes, but is not limited to, personal identifying information such as name, gender, age, contact details such as email his address. Address telephone (mobile or otherwise) and/or fax number. This information may also include information such as bank details (including credit/debit card) and other information about your income and lifestyle. Billing information, payment history, etc. (that you share with us).</p>
                                        <p className="" >2. Other Information: We also collect other information and materials, including but not limited to:</p>
                                        <p className="" >3. Transaction history (in addition to bank details) related to your e-commerce activities, purchasing behaviour.</p>
                                        <p className="" >4. Usernames, passwords, email addresses and other security-related information you use in connection with our Services. </p>
                                        <p className="" >5. Data created by you or a third party that you want to store on our servers, such as image files, documents, etc. </p>
                                        <p className="" >6. Data available in the public domain or received from any third party, including social media, including but not limited to non-personal information from linked social media channels. Your link (such as your name, email address, email, friends list, profile picture, or other information authorized to receive under your account settings) as part of your account information. </p>
                                        <p className="" >7. Information regarding any other travellers you book through your registered BookUrSelf account. In this case, you must confirm and declare that each other traveller who has made the reservation has consented to the information you share being disclosed to us and we then share that information with you. Relevant service provider(s). </p>
                                    </div> }
                                </div>
                                <div className="accordion-section">
                                    <div className="accordion-header">
                                        <h5>How We Use Your Personal Information</h5>
                                        <div className="arrow me-4" >

                                            {

                                                accordion === 4 ? <IoIosEyeOff className='acc-icon' onClick={() => setAccordion(0)} /> : <IoIosEye className='acc-icon1' onClick={() => setAccordion(4)} />

                                            }


                                        </div>
                                    </div>
                                    {accordion === 4 && <div className="accordion-content">
                                        <p className="">The Personal Information collected may be used in the following ways:</p>
                                        <h5 className="subhead-pading">1. When booking: </h5>
                                        <p className="" >When making a reservation, we may use personal information including payment details including cardholder name, credit/debit card number (in encrypted form) with expiration date, bank details rows, wallet details, etc., as shared and with your permission to store. We may also use traveller list information available in or associated with your account. This information is displayed to the User at the time of booking to enable you to complete your booking quickly.</p>
                                        <h5 className="subhead-pading">2. We may also use your personal information for a number of purposes, including but not limited to:</h5>
                                        <p className="" >1. Keep you informed of the status of the transaction;</p>
                                        <p className="">2. Usernames, passwords, email addresses and other security-related information you use in connection with our Services.</p>
                                        <p className="" >3. Send booking confirmation by SMS, email or any other messaging service;</p>
                                        <p className="" >4. Send any updates or changes to your reservation(s);</p>
                                        <p className="" >5. Allow our customer service to contact you, if necessary;</p>
                                        <p className="" >6. Customize the content of our websites, mobile pages and mobile applications;</p>
                                        <p className="" >7. Consult about products or services or any other improvement;</p>
                                        <p className="" >8. Send message(s) or verification email(s);</p>
                                        <p className="" >9. Authenticate/authenticate your account and prevent misuse or abuse.</p>
                                        <h5 className="subhead-pading">3. SURVEY:</h5>
                                        <p className="" >We value user opinions and feedback and regularly conduct surveys, both online and offline. Participation in these surveys is completely voluntary. Normally, the information received is aggregated and used to improve the website, other sales channels and services, and develop content, features and promotions that attract members based on survey results. . The identities of survey participants are anonymous unless otherwise stated in the survey.</p>
                                        <h5 className="subhead-pading">User-Generated Content (UGC)</h5>
                                        <p className="" >BookUrSelf gives users the ability to publish their experiences in the form of reviews, ratings, and general polls. Customers also have the option to post questions about services provided by BookUrSelf or to post answers to questions posed by other users. BookUrSelf may also invite a third party to contact you and collect feedback on your recent bookings with BookUrSelf. While participating in the feedback process is completely optional, you may still receive an email, notification (SMS, email or any other messaging service) for you to share your feedback. Reviews can be written or in video form. Reviews written or posted may also show up on other travel or travel related platforms.</p>
                                        <p className="" >The UGCs collected by BookUrSelf can be of the following types:</p>
                                        <p className="" >1. Comments and reviews.</p>
                                        <p className="" >2. Questions and answers.</p>
                                        <p className="" >3. Crowd data collection (survey questions).</p>
                                        <h5 className="subhead-pading">4. PROMOTIONS, RESEARCH AND MARKETING PROGRAMS:</h5>
                                        <p className="" >Promotions, research and marketing help us identify your preferences, develop programs and improve user experience. BookUrSelf regularly sponsors promotions to give users the chance to win amazing rides and travel-related prizes. The personal information we collect for such activities may include contact information and survey questions. We use this personal information to notify contest winners and survey information to develop promotions and product improvements. As a registered user, you will also occasionally receive updates from us on ticket sales in your area, special offers, new BookUrSelf services, great items other (such as savings and benefits when buying bus tickets, hotel reservations, pilgrimage packages, car rental and other travel services) and marketing programs.</p>
                                        <p className="" >In addition, you can expect to receive periodic marketing emails, newsletters and exclusive promotions offering special offers.</p>
                                        <p className="" >From time to time, we may add or improve the services available on the website. To the extent these services are provided and used by you, we will use the personal information you provide to facilitate the requested service(s). For example, if you email us a question, we will use the email address, name, nature of the question, etc. to answer your question. We may also store this personal information to help us make the website better and easier for our users</p>
                                        <p className="" >BookUrSelf may from time to time launch rewards programs through which users can earn travel-related rewards or other rewards. We may use your personal information to enrol you in the Rewards Program and the status of the Rewards Program will be displayed each time you log into the Website. Depending on the rewards program, each time you earn a reward, BookUrSelf may share your personal information with a third party who will be responsible for transferring the reward to you. However, you can opt out of these rewards programs by writing to us. For various purposes, such as detecting fraud, providing booking credit, etc., we may from time to time verify customer information on a selective basis, including their credit information.</p>
                                    </div> }
                                </div>
                                <div className='accordion-section'>
                                    <div className="accordion-header">
                                        <h5>How Long Do We Keep Your Personal Information?</h5>
                                        <div className="arrow me-4" >

                                            {

                                                accordion === 5 ? <IoIosEyeOff className='acc-icon'  onClick={() => setAccordion(0)} /> : <IoIosEye className='acc-icon1'  onClick={() => setAccordion(5)}/>

                                            }

                                        </div>
                                    </div>
                                    {accordion === 5 && <div className="accordion-content">
                                        <p className="" >BookUrSelf will keep your personal information on its servers for as long as reasonably necessary for the purposes listed in this policy. In certain circumstances, we may retain your personal information for longer periods of time, such as when we are required to do so by legal, regulatory, tax or accounting requirements.</p>
                                    </div> }
                                </div>
                                <div className="accordion-section">
                                    <div className="accordion-header">
                                        <h5>Cookies and Session Data</h5>
                                        <div className="arrow me-4" >

                                            {

                                                accordion === 6 ? <IoIosEyeOff className='acc-icon' onClick={() => setAccordion(0)} /> : <IoIosEye className='acc-icon1' onClick={() => setAccordion(6)} />

                                            }

                                        </div>
                                    </div>
                                    {accordion === 6 && <div className="accordion-content">
                                        <h5 className="subhead-pading">1. Cookies </h5>
                                        <p className="" >BookUrSelf uses cookies to personalize your experience on the Website and the advertisements that may be displayed on it. BookUrSelf' use of cookies is similar to that of any other reputable online company.</p>
                                        <p className="" >Cookies are small pieces of information that are stored by your browser on your device's hard drive. Cookies allow us to serve you better and more efficiently. Cookies also allow easy access, by logging in without having to enter your username each time (just your password); We may also use these cookies to display advertising to you while you are on the Site or to send you offers (or similar emails - provided you have not opted out of receiving these such emails) focus on destinations that may be of interest to you.</p>
                                        <p className="" >A cookie may also be set by our ad servers or by third party advertising companies. These cookies are used for the purpose of tracking the effectiveness of the advertising we serve on any website, and also to use aggregate statistics about your visit to the website to serve ads advertised on the website or on any other website regarding services that may be of potential interest. You care. Advertising agencies or third-party advertising providers may also use technology used to measure the effectiveness of advertising. All this information is anonymous. This anonymized information is collected through the use of pixel tags, which is an industry standard technology and is used by all major websites. They may use this anonymous information about your visits to the website to provide advertisements for goods and services that may be of interest to you. No personal information is collected during this process. Therefore, the information collected in this process is anonymous and does not link online actions to any user.</p>
                                        <p className="" >Most web browsers automatically accept cookies. Of course, by changing your web browser preferences or using certain software, you can control how and whether cookies are accepted by your browser. BookUrSelf supports your right to block unwanted internet activity, especially from unscrupulous websites. However, blocking BookUrSelf cookies may disable certain functions on the Website and may interfere with the experience of purchasing or using certain services on the Website. Please note that it is possible to block cookie activity from certain websites while still allowing cookies from sites you trust.</p>
                                        <h5 className="subhead-pading">2. Automatic session data logging: </h5>
                                        <p className="" >Each time you visit the website, your session data is saved. Session data can include various aspects such as IP address, operating system and type of browser software used, and activities performed by users on the website. We collect session data because it helps us analyze user choices, browsing patterns, including frequency of visits, and how long the user is logged in. It also helps us diagnose problems with our servers and allows us to better administer our systems. The aforementioned information does not personally identify the User. However, the user's Internet Service Provider (ISP) and the approximate geographic location of the user's connection point can be determined through the session data above.</p>
                                    </div> }
                                </div>
                                <div className="accordion-section">
                                    <div className="accordion-header">
                                        <h5>Why Your Personal Information Is Shared</h5>
                                        <div className="arrow me-4" >

                                            {

                                                accordion===7 ? <IoIosEyeOff className='acc-icon' onClick={() => setAccordion(0)} /> : <IoIosEye className='acc-icon1' onClick={() => setAccordion(7)} />

                                            }

                                        </div>
                                    </div>
                                    {accordion===7 && <div className="accordion-content">
                                        <h5 className="subhead-pading">1. Providers and Service Providers:</h5>
                                        <p className="" >Your information will be shared with terminal service providers such as hotels, bus service providers, taxi rental agencies or any other service providers responsible for making bookings. You may note that by booking with BookUrSelf, you authorize us to share your information with those service providers and providers. It should be noted that BookUrSelf does not allow end service providers to use your information for any other purpose, except to perform their part of the service. However, how those service providers/providers use the information shared with them is beyond the authority and control of BookUrSelf as they handle personal information as data controllers. Material is independent, and therefore we cannot be held responsible. Therefore, you should review the privacy policies of the carrier or carrier you choose to take advantage of.</p>
                                        <p className="" >BookUrSelf does not sell or rent personal customer names or other personal user information to third parties, except to share such information with our business/alliance partners or suppliers without we engage to provide various services and to share promotional and other benefits with our customers from time to time based on their booking history with us.</p>
                                        <h5 className="subhead-pading">2. Related Companies:</h5>
                                        <p className="" >In the interest of improving the personalization and efficiency of the service, we may, under controlled and secure circumstances, share your personal information with affiliates or affiliates our end.</p>
                                        <p className="" >If BookUrSelf assets are acquired, our customer information may also be transferred to the acquirer depending on the nature of the acquisition. In addition, as part of an expansion / development / restructuring of the business or for any other reason, if we decide to sell / transfer / disburse our business, any part of the it, any of our subsidiaries or any business unit, then as part of this undertakes restructuring of customer information, including personal information collected herein, will be transferred accordingly.</p>
                                        <h5 className="subhead-pading">3. Business Partner And Third Party Supplier:</h5>
                                        <p className="" >We may also share certain filtered personal information with our affiliates or business partners who may contact customers to provide certain products or services which may include free or paid products/services, help customers have a better travel experience or take advantage of certain advantages specially designed for BookUrSelf customers. Examples of such partners are organizations that provide co-branded credit cards, travel insurance, wallet loss insurance, bank cards or similar sensitive information, and more. If you choose to take advantage of these services provided by our business partners, such used services will be governed by the privacy policy of the respective service provider.</p>
                                        <p className="" >BookUrSelf may share your personal information with third parties to which BookUrSelf may engage to perform certain tasks on its behalf, including but not limited to payment processing, data storage and data processing. We use users' non-personally identifiable information in aggregate or anonymized form to create better and more useful online services by performing statistical analysis of characteristics and behaviours common to our customers and visitors, and measure demographics and interests related to specific areas of the Site. We may provide anonymized statistical information based on this data to current and potential suppliers, advertisers, affiliates and business partners. We may also use this aggregated data to let third parties know how many people have viewed and clicked on links to their websites. All personal information that we collect and may use in an aggregated format is our property. We may use them, in our sole discretion and without any compensation to you, for lawful purposes, including without limitation, selling them to third parties. Sometimes BookUrSelf will engage a third party for market research, surveys, etc. and will make specific information available to such third parties for use in connection with such projects. The information (including aggregate cookies and tracking information) that we provide to third parties, alliance partners or suppliers is protected by confidentiality agreements and such information is used only to carry out a particular project and comply with applicable regulations.</p>
                                        <h5 className="subhead-pading">4. Information Disclosure</h5>
                                        <p className="" >In addition to the circumstances described above, BookUrSelf may disclose User Personal Information as necessary:</p>
                                        <p className="" >1. By law, required by any law enforcement agency for investigative purposes, by order of a court, or by reference to any legal process;</p>
                                        <p className="" > 2. To conduct our business;
                                        </p><p className="" >3. for regulatory, internal compliance and audit exercises</p>
                                        <p className="" > 4. To secure our systems; Where</p>
                                        <p className="" > 5. to exercise or protect our rights or property in respect of BookUrSelf or any or all of our affiliates, partners, employees, directors or officers, or when we have reason to believe that the disclosure of a user's personal information is necessary to identify, contact, or initiate a lawsuit against someone who may be infringing upon our rights or property, knowingly or unknowingly, or where others have may be harmed by such activities.</p>
                                        <p className="" >This disclosure and storage may take place without your knowledge. In such event, we shall not be liable to you or any third party for any damages resulting from such disclosure and storage.</p>
                                    </div> }
                                </div>
                                <div className='accordion-section'>
                                    <div className="accordion-header">
                                        <h5> How Can You Stop Receiving Our Promotional Emails?</h5>
                                        <div className="arrow me-4" >

                                            {

                                                accordion===8 ? <IoIosEyeOff className='acc-icon' onClick={() => setAccordion(0)} /> : <IoIosEye className='acc-icon1' onClick={() => setAccordion(8)} />

                                            }

                                        </div>
                                    </div>
                                    {accordion===8 && <div className="accordion-content">
                                        <p className="" >Periodically, we'll send you emails with information about fare sales near you, exclusive deals, brand-new BookUrSelf services, and other important things. These updates are meant to be educational and fascinating, we hope. Please use the "unsubscribe" link or adhere to the instructions in each email message if you do not want to receive them.</p>
                                        <h5 className="subhead-pading">Permissions Needed To Use Our Mobile Applications</h5>
                                        <p className="" >A list of permissions that are required for the BookUrSelf app to work properly appears when it is installed on your phone or tablet. The list cannot be altered in any way. The data that must be obtained by BookUrSelf and how it will be used are as follows:</p>
                                    </div> }
                                </div>
                                <div className='accordion-section'>
                                    <div className="accordion-header">
                                        <h5> Android permissions </h5>
                                        <div className="arrow me-4" >


                                            {

                                                accordion===9 ? <IoIosEyeOff className='acc-icon' onClick={() => setAccordion(0)} /> : <IoIosEye className='acc-icon1' onClick={() => setAccordion(9)} />

                                            }


                                        </div>
                                    </div>
                                    {accordion===9 && <div className="accordion-content">
                                        <p className="" >1. Device and App History: In order to obtain information about your device, such as the OS (operating system) name, OS version, mobile network, hardware model, unique device identification, preferred language, etc., we require your device's permission. We want to improve your travel booking experience based on these inputs.</p>
                                        <p className="" >2. Identity: With this permission, we are allowed to access information about your mobile device's account(s). We utilise this information to give a typing-free experience by auto-filling your email addresses. Additionally, it enables us to associate email IDs with specific users so that you can take advantage of special travel promotions, wallet cash-backs, etc.</p>
                                        <p className="" >3. Location: This permission enables us to give you the benefit of location specific deals and provide you a personalized experience. We automatically detect your position when you use the BookUrSelf app to schedule a trip, therefore the nearest city is pre-filled. In order to assist you in tracking your bus in relation to your position, we also need this permission.</p>
                                        <p className="" >4. SMS: If you give us permission to view your SMS, we will read it to confirm your mobile number and to automatically fill or pre-populate the "OTP" field during a transaction. With no need to exit the app to read the SMS and then enter it, you get a smooth purchasing experience when making a reservation.</p>
                                        <p className="" >5. Phone: The app requires access to make phone calls, so you can call our bus operators, hotels and customer contact canters directly through the app.</p>
                                        <p className="" >6. Contacts: If you give us access to your contacts, we may offer you several social services like sharing tickets or your location with your friends. You can choose phone numbers from your contacts while using the app to recharge your mobile device thanks to this permission.</p>
                                        <p className="" >7. Photos/Media/Files: The Gallery app uses these permissions to allow users to save and download media reviews.</p>
                                        <p className="" >8. Wi-Fi connection information: When you allow us to detect your Wi-Fi connection, we optimize your bandwidth usage for downloading media.</p>
                                        <p className="" >9. Device ID and call information: This permission is used to detect your Android ID by which we can uniquely identify the user. It also allows us to know your contact details by using us to pre-populate specific fields to ensure a seamless booking experience.</p>
                                        <p className="" >10. Camera: This permission is used to take pictures of the boarding point or bus before travelling. These images can then be uploaded as part of media reviews.</p>
                                        <p className="" >11. Calendar: This permission allows us to include your travel plans in your calendar.</p>
                                    </div> }
                                </div>
                                <div className="accordion-section">
                                    <div className="accordion-header">
                                        <h5> How Do We Safeguard Your Personal Data? </h5>
                                        <div className="arrow me-4" >

                                            {

                                                accordion===10 ? <IoIosEyeOff className='acc-icon' onClick={() => setAccordion(0)} /> : <IoIosEye className='acc-icon1' onClick={() => setAccordion(10)} />

                                            }


                                        </div>
                                    </div>
                                    {accordion===10 && <div className="accordion-content">
                                        <p className="" >All payments on the site are secure. This means that all personal data you provide is transmitted using TLS (Transport Layer Security) encryption. TLS is a proven encryption system that allows browsers to automatically encrypt or encrypt data before sending it to us. The website maintains strict security measures to protect against loss, misuse and alteration of the information under our control. Whenever you change or access your account information, you can use our secure servers. Once we have your information, we adhere to strict security policies to protect against unauthorized access.</p>
                                    </div> }
                                </div>
                                <div className="accordion-section">
                                    <div className="accordion-header">
                                        <h5> Withdrawal Of Consent And Approval </h5>
                                        <div className="arrow me-4" >


                                            {

                                                accordion===11 ? <IoIosEyeOff className='acc-icon'  onClick={() => setAccordion(0)} /> : <IoIosEye className='acc-icon1' onClick={() => setAccordion(11)} />

                                            }


                                        </div>
                                    </div>
                                    {accordion===11 && <div className="accordion-content" >
                                        <p className="" >You can withdraw your consent to the transfer of some or all of your personal information at any time. You can also opt out of giving us permission on our website, as described above. If you choose to do so, your access to our website may be restricted or we may not be able to provide you with our services. To withdraw your consent, please send an email to privacy@bookurself.com.</p>
                                    </div> }
                                </div>
                                <div className='accordion-section'>
                                    <div className="accordion-header">
                                        <h5> Rights As Personal Information </h5>
                                        <div className="arrow me-4"  >


                                            {

                                                accordion===12 ? <IoIosEyeOff className='acc-icon' onClick={() => setAccordion(0)} /> : <IoIosEye className='acc-icon1' onClick={() => setAccordion(12)} />

                                            }

                                        </div>
                                    </div>
                                    {accordion===12 && <div className="accordion-content">
                                        <p className="" >You can access your personal data through your BookUrSelf user account. You can also correct or delete your personal data directly from your user account (with the exception of some mandatory fields). If you do not have such a user account, please contact us at privacy@bookurself.com.</p>
                                    </div> }
                                </div>
                                <div className='accordion-section'>
                                    <div className="accordion-header">
                                        <h5>Authorization To Trade With Bookurself </h5>
                                        <div className="arrow me-4" >


                                            {

                                                accordion===13 ? <IoIosEyeOff className='acc-icon' onClick={() => setAccordion(0)}/> : <IoIosEye className='acc-icon1' onClick={() => setAccordion(13)} />

                                            }


                                        </div>
                                    </div>
                                    {accordion===13 && <div className="accordion-content">
                                        <p className="" >We reserve the right to revise our Privacy Policy from time to time to suit various legal, business and customer needs. We will notify you appropriately when necessary.</p>
                                    </div> }
                                </div>
                                <div className="accordion-section">
                                    <div className="accordion-header">
                                        <h5> Changes To Privacy Policy </h5>
                                        <div className="arrow me-4" >


                                            {

                                                accordion===14 ? <IoIosEyeOff className='acc-icon' onClick={() => setAccordion(0)} /> : <IoIosEye className='acc-icon1' onClick={() => setAccordion(14)}/>

                                            }

                                        </div>
                                    </div>
                                    {accordion===14 && <div className="accordion-content">
                                        <p className="" >You have to be at the least 18 years of age to transact at once with BookUrSelf and additionally to consent to the processing of your personal data.</p>
                                        <p className="" >You can always email us at privacy@bookurself.com with questions or complaints about this privacy policy. BookUrSelf will make an effort to address all valid issues and questions.</p>
                                    </div> }
                                </div>
                                <div className='accordion-section'>
                                    <div className="accordion-header">
                                        <h5> Deleting Accounts Or Personal Data </h5>
                                        <div className="arrow me-4" >

                                            {

                                                accordion===15 ? <IoIosEyeOff className='acc-icon' onClick={() => setAccordion(0)} /> : <IoIosEye className='acc-icon1' onClick={() => setAccordion(15)} />

                                            }


                                        </div>
                                    </div>
                                    {accordion===15 && <div className="accordion-content">
                                        <p className="" >If you would like to delete your account or personal information, please send an email to privacy@bookurself.com and request that your account be deleted. Please note that our Data Protection Officer may require us to verify your identity before processing your account deletion request. The ID you provide will be retained for 21 days from the date of account deletion.</p>
                                    </div> }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PrivacyPolicy