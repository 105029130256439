import React from 'react'
import { Sidebar } from './MyBookings'

const MyProfile = () => {
  return (
    <>
  <div className="container mt-5 login_head_section ">


<div className="login_section">

  <div className="login_sidebar">
    <Sidebar />
  </div>

  <div className="login_details">

    <div className='my_booking_section container '>

      <div className="row pt-4">
        <div className='col-md-6 '>
          <div>
            <label htmlFor="">Name</label>
          </div>
          <input type="text" 
          className='ba_input'
           />
        </div>

        <div className='col-md-6'>
          <div>
            <label htmlFor="">Email</label>
          </div>
          <input type="text" className='ba_input' />
        </div>
      </div>

      <div className='row mt-3'>

        <div className='col-md-6'>
          <div>
            <label htmlFor="">Gender</label>
          </div>
          <input type="text" className='ba_input' />
        </div>

        <div className='col-md-6'>
          <div>
            <label htmlFor="">Mobile Number</label>
          </div>
          <input type="text"  className='ba_input' />
        </div>
      </div>
      <button className='mt-4 mb-4  btn2' >Save</button>
    </div>
  </div>
</div>
</div>

    </>
  )
}

export default MyProfile