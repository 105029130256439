import React from 'react';
import './contactus.css'
import { BiLogoFacebook } from "react-icons/bi";
import { FaLocationDot,FaPhone,FaEnvelope,FaPenToSquare} from "react-icons/fa6";
import { FaFacebookF,FaTwitter,FaInstagram,FaUser} from "react-icons/fa";
import { Link } from 'react-router-dom';


const ContactUs = () => {
  return (

    <div className="contactus " >
        <div className='container pt-5 pb-5'>
            {/* <div className='contactUsSection'>
              <div className="contactUsSectionLft">Left</div>
              <div className='contactUsSectionRgt'>Right</div>
            </div> */}

            
<div className="row contactUsSection ">
                <div className="col-lg-8 contactUsSectionLft">
                    <div className="form-box">
                        <div className="form-title-wrap">
                            <h5 className="contactUsSectionLfttitle">We'd love to hear from you</h5>
                            <p className="">Send us a message and we'll respond as soon as possible</p>
                        </div>
                        <div className="form-content ">
                            <div className="contact-form-action">
                                <form method="post" className="row">
                                    <div className="col-lg-6 responsive-column contact-form-action-div">
                                        <div className="input-box">
                                            <label className="label-text">Your Name</label>
                                            <div className="form-group">
                                                <FaUser className='form-icon'/>
                                                <input className="form-control" type="text" name="text" placeholder="Your name"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 responsive-column contact-form-action-div">
                                        <div className="input-box">
                                            <label className="label-text">Your Email</label>
                                            <div className="form-group">
                                            <FaEnvelope className='form-icon'/>
                                                <input className="form-control" type="email" name="email" placeholder="Email address"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 contact-form-action-div">
                                        <div className="input-box">
                                            <label className="label-text">Message</label>
                                            <div className="form-group">
                                               <FaPenToSquare className='form-icon' />
                                                <textarea className="message-control form-control" name="message" placeholder="Write message"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-12 contact-form-action-div">
                                        <div className="btn-box">
                                            <button type="button" className="theme-btn">Send Message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 contactUsSectionRgt ">
                    <div className="form-box">
                        <div className="form-title-wrap">
                            <h5 className="contactUsSectionRgttitle">Contact Us</h5>
                        </div>
                        <div className="form-content">
                            <div className="address-book">
                                <ul className="list-items contact-address" >
                                    <li>
                                        <span><Link className='ban-bottom1'><FaLocationDot/></Link></span>
                                        <div className='contactUsSectionRgtLable'>
                                        <label className="label-text font-size-16">Address</label>
                                        <p className="map__desc">
                                        HOPCOMS, Society CTS No. 3921, A1/A, 2nd Floor,  Court Campus, Belagavi, Karnataka 590001
                                        </p>
                                        </div>
                                    </li>
                                    <li>
                                        <span><Link className='ban-bottom1'><FaPhone/></Link></span>
                                        <div className='contactUsSectionRgtLable'>
                                        <label className="label-text font-size-16">Phone</label>
                                        <p className="map__desc">Mobile: +91 9964 566 363</p>
                                        </div>
                                    </li>
                                    <li>
                                        <span><Link className='ban-bottom1'><FaEnvelope /></Link></span>
                                        <div className='contactUsSectionRgtLable'>
                                        <label className="label-text font-size-16">Email</label>
                                        <p className="map__desc">customercare@bookurself.com</p>
                                        </div>
                                        
                                    </li>
                                </ul>
                                <ul className="social-profile  ">
                                    <li><Link className='ban-bottom'><FaFacebookF/></Link></li>
                                    <li><Link className='ban-bottom'><FaTwitter/></Link></li>
                                    <li><Link className='ban-bottom'><FaInstagram/></Link></li>                       
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
  )
}

export default ContactUs;