import React from 'react'
import './OfferPageCss/offerpage.css'


const Firstbus = () => {
    return (
        <div>
            <div className=" container mt-5 referalOfferMain " style={{ visibility: "visible", }}>
                <div className="offer-container-details" style={{ boxShadow: "0 3px 30px 0 rgb(0 0 0 / 10%)", backgroundColor: "#fff", padding: "30px 20px 30px 20px", borderRadius: "10px", zIndex: "100" }}>
                    <h1 className="rupes-left   my-5 " style={{ visibility: "visible", animationName: "fadeInDown", fontFamily: "Lato, sans-serif", color: "rgb(192, 24, 150)", fontSize: "30px", fontWeight: "bold", paddingLeft: "15px" }}>Offer details</h1>

                    <div className=" referalOfferSection mb-5">
                        <div className="  container referalOfferSectionContainer p-0 ">
                            <div className=" referalOfferHeader">
                                <h4 className='' style={{fontSize:"18px"}}> Offer Highlights </h4>
                            </div>
                            <div className=" text-center  row">

                                <div className="col-6 p-2" style={{ borderRight: "1px dashed #d5d5d5" }}>
                                    <p className="referalOffer-sub-title" style={{ color: "#666666", textTransform: "uppercase", fontSize: "13px", letterSpacing: "-0.3px", lineHeight: "normal", fontWeight: "bold", }}>COUPON CODE</p>
                                    <p className="referalOffer-sub-details" style={{ fontSize: "16px", color: "#2f68b5", textTransform: "capitalize", padding: "0", margin: "0", lineHeight: "normal" }}>FIRSTBUS</p>


                                </div>
                                <div className="col-6 p-2">
                                    <p className="referalOffer-sub-title" style={{ color: "#666666", textTransform: "uppercase", fontSize: "13px", letterSpacing: "-0.3px", lineHeight: "normal", fontWeight: "bold" }}>Validity</p>

                                    <p className="referalOffer-sub-details" style={{ fontSize: "16px", color: "#2f68b5", textTransform: "capitalize", padding: "0", margin: "0", lineHeight: "normal" }} >3rd January 2025</p>



                                </div>
                            </div>

                        </div>


                    </div>


                    <div className="referalOfferTC ">
                        <div className="referalOfferTCSection border">
                            <div className="referalOfferTC-heading">
                                <h4 className='' style={{fontSize:"18px"}}>Terms and Conditions</h4>

                            </div>
                            <div className="referalOfferTC-body">

                                <p className="text-[16px] text-[#333333]">PROMO CODE: FIRSTBUS Save up to Rs.100 Cashback on bus ticket booking. The offer is valid for one-time usage per user. The minimum order value is Rs.300. The offer is valid for all users. To avail of the offer, the user needs to have a verified mobile number on BookUrSelf. Canceled orders will not be eligible for cashback. BookUrSelf reserves its absolute right to withdraw and/or alter any terms and conditions of the offer at any time without prior notice.</p>




                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Firstbus