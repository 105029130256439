import React from 'react'
import { Sidebar } from './MyBookings'

const Notification = () => {
  return (
    <>
      <div className="container mt-5 login_head_section">


<div className="login_section">

  <div className="login_sidebar">
    <Sidebar />
  </div>

  <div className="login_details">

    <div className='my_booking_section container '>

      <div className='NotificationSec'>
        
      <div className='NotificationSecHeader pt-4 pb-2' >
        <h5>Notifications Settings</h5>
        <p>I would like to be notified for any offers from bookurself.com through</p>
      </div>

      <div className='d-inline-flex  p-2 mb-3 NotificationSecCheck'>

        <h6 className=' m-0'>Email Alerts</h6>
        <input type="checkbox" className='ms-2' />

      </div>

      
      <div className='d-inline-flex p-2  ms-5 mb-3 NotificationSecCheck'>

        <h6 className='m-0'>Message Alerts</h6>
        <input type="checkbox" className='d-flex align-items-center justify-content-center ms-2' />

      </div>

      </div>
    </div>
  </div>
</div>
</div>
    </>
  )
}

export default Notification