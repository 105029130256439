import React, { useState } from 'react'
import { Sidebar } from './MyBookings';
import { FaArrowLeftLong } from "react-icons/fa6";


const MyTravellersList = () => {

  const [travellerSec, setTravellerSec] = useState(false)

  return (
    <>


      <div className="container mt-5 login_head_section ">


        <div className="login_section">

          <div className="login_sidebar">
            <Sidebar />
          </div>

          <div className="login_details">

            <div className='my_booking_section container  pt-4 '>

              <div className='d-flex justify-content-between myTraveller_sec' onClick={()=>setTravellerSec(true)}>
                <h5>Saved Passengers</h5>
                <div><p> <span >+</span> Add New Traveller</p></div>
              </div>

              <div className='mt-4 mb-4'>
                <div className='text-center booking_about'>No Saved Passengers Found!</div>
              </div>


            </div>
          </div>
        </div>
      </div>

      {travellerSec ?  <div className='d-flex  addTravellerSec ' >

        <div className='' style={{width:"100%"}}>

          <div onClick={()=>setTravellerSec(false)} className='addTravellerSecone'>
          <span>
          <FaArrowLeftLong />
          </span>
          <p  className='' >Add New Traveller</p>
          </div>
        
          <div className='addTravellerInputSec'>

            <div className='addTravellerinfo'>
              <label htmlFor="" style={{display:"block"}}>Name</label>
              <input type="text" />
            </div>

            <div className='addTravellerinfo'>
              <label htmlFor="" style={{display:"block"}}>Age</label>
              <input type="text" />
            </div>

            <div className='mt-4 addTravellerRadioSec'>
              <p className='pb-4'>Select the gender</p>
              <input type="radio" id="men" name="fav_language" value="men" />
              <label for="men" >Men</label>
              <input type="radio"  className='addTravellerRadioSec_i' id="women" name="fav_language" value="women" />
              <label for="women">Women</label>
            </div>

            <button>Save Passenger</button>

          </div>
        </div>
      </div>:null}


    </>
  )
}

export default MyTravellersList;