import React from 'react'
import './LoginPageCss/loginpagedetail.css'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { GiTicket } from "react-icons/gi";
import { PiUsersThreeFill } from "react-icons/pi";
import { FaUserCircle } from "react-icons/fa";
import { MdLocationPin, MdNotificationsActive } from "react-icons/md";
import { travelledDetail } from '../json file/travelledDetail';






const MyBookings = () => {

  const [tabState, setTabState] = useState(1);

  const [tableData, setTableData] =useState(travelledDetail);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const indexOfLastItem = currentPage * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(tableData.length / rowsPerPage);


  const handlePrev = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1)

    )
}

const handleNext = () => {
    setCurrentPage((prev) => Math.min(prev + 1, ))
}

const handlePageClick = (index) => {
    setCurrentPage(index)
}


const handlefiveValue = () => {
  setRowsPerPage(5)
  setCurrentPage(1)
}

const handleTenValue = () => {
  setRowsPerPage(10)
  setCurrentPage(1)
}

const handleFiftenValue = () => {
  setRowsPerPage(15)
  setCurrentPage(1)
}

  

  return (
    <>



      <div className="container mt-5   login_head_section ">
        <div className="login_section">

          <div className="login_sidebar">
            <Sidebar />
          </div>

          <div className="login_details">
            <div className='border my_booking_section  ' style={{}}>

              <div className='my_booking_section_header'>
                <p onClick={() => setTabState(1)} className={`${tabState === 1 && 'bottom_padding'}`} style={{cursor:"pointer"}}>Upcoming</p>
                <p onClick={() => setTabState(2)} className={`${tabState === 2 && 'bottom_padding'}`} style={{cursor:"pointer"}}>Past</p>
                <p onClick={() => setTabState(3)} className={`${tabState === 3 && 'bottom_padding'}`} style={{cursor:"pointer"}}>Cancelled</p>
              </div>

              {tabState === 1 && <> <div className='mt-4'>
                <div className='container d-flex justify-content-between'>
                  <div>
                  <h5 className='ms-5'>Upcoming Bookings</h5>
                  </div>
                  {/* <div className='paginationValueBtn me-4 '>
                    <button onClick={handlefiveValue}>5</button>
                    <button onClick={handleTenValue}>10</button>
                    <button onClick={handleFiftenValue} >15</button>
                  </div> */}
                  <select name="cars" id="cars">
                  <option onClick={handlefiveValue} >5</option>
                  <option onClick={handleTenValue}>10</option>
                  <option onClick={handleFiftenValue}>15</option>
                 </select>

                </div>
                <div className='container'>
                {
                    currentItems.map((val,index)=>(
                      <>
                      <div className='travelled_Datail container mt-2 mb-2'>

                        <div className='travelled_header d-flex justify-content-between'>
                          <h6 className=''><span className='travelled_bus_icon'>{val.busIcon}</span>{val.busDetail}</h6>
                          <p className="travelled_status1">{val.status1}</p>
                        </div>

                        <div className='travelled_city'>
                          <span>{val.from}</span><span>-</span><span>{val.to}</span>
                        </div>

                        <div className='travelled_footer d-flex justify-content-between'>
                         <div className='d-flex align-items-center justify-content-center'>
                            <span className='travelled_date_icon'>{val.dateIcon}</span>
                            <p className=''>{val.dateDetail}</p>
                         </div>
                          <div>
                            <button className='travelled_btn1'>{val.cancelTicket}</button>
                            <button className="travelled_btn2">{val.printTicket}</button>
                          </div>
                        </div>
                      </div>
                      </>
                    ))
                  }
                  
                <div className='pagination'>
                    <button onClick={handlePrev} disabled={currentPage === 1} className='paginationPrev'>Prev</button>
                    {
                        Array.from({ length: totalPages }, (_, index) => (
                            <button onClick={() => handlePageClick(index + 1)} className={`paginationbtn ${currentPage === index + 1 ? "active" : ""}`}>{index + 1}</button>
                        ))
                    }
                    <button onClick={handleNext} disabled={currentPage === totalPages} className='paginationNext'>Next</button>
                </div>

                </div>
              </div>

                <div className='d-flex justify-content-center p-4'>
                  <img src="/Images/Login Detail Section Images/1.png" height="80px" alt="" style={{ borderRadius: "50%" }} />
                </div>

                <button className='btn1'>Book Now</button></>}


              {tabState === 2 && <>  <div className='mt-4'>
                <div className='container d-flex justify-content-between'>
                  <div>
                  <h5 className='ms-5'>Past Bookings</h5>
                  </div>
                  <div className='paginationValueBtn me-4 '>
                    <button onClick={handlefiveValue}>5</button>
                    <button onClick={handleTenValue}>10</button>
                    <button onClick={handleFiftenValue} >15</button>
                  </div>
                </div>
                <div className='container'>

                  {
                    currentItems.map((val,index)=>(
                      <>
                      <div className='travelled_Datail container mt-2 mb-2'>

                        <div className='travelled_header d-flex justify-content-between'>
                          <h6 className=''><span className='travelled_bus_icon'>{val.busIcon}</span>{val.busDetail}</h6>
                          <p className="travelled_status2">{val.status2}</p>
                        </div>

                        <div className='travelled_city'>
                          <span>{val.from}</span><span>-</span><span>{val.to}</span>
                        </div>

                        <div className='travelled_footer d-flex justify-content-between'>
                         <div className='d-flex align-items-center justify-content-center'>
                            <span className='travelled_date_icon'>{val.dateIcon}</span>
                            <p className=''>{val.dateDetail}</p>
                         </div>
                          <div><button className="travelled_btn2">{val.printTicket}</button></div>
                        </div>
                      </div>
                      </>
                    ))
                  }
                  
                <div className='pagination'>
                    <button onClick={handlePrev} disabled={currentPage === 1} className='paginationPrev'>Prev</button>
                    {
                        Array.from({ length: totalPages }, (_, index) => (
                            <button onClick={() => handlePageClick(index + 1)} className={`paginationbtn ${currentPage === index + 1 ? "active" : ""}`}>{index + 1}</button>
                        ))
                    }
                    <button onClick={handleNext} disabled={currentPage === totalPages} className='paginationNext'>Next</button>
                </div>
          
                </div>
              </div>

                <div className='d-flex justify-content-center p-4'>
                  <img src="/Images/Login Detail Section Images/1.png" height="80px" alt="" style={{ borderRadius: "50%" }} />
                </div>

                <button className='btn1'>Book Now</button></>}


              {tabState === 3 && <> <div className='mt-4'>
                <div className='container d-flex justify-content-between'>
                  <div>
                  <h5 className='ms-5'>Cancelled Bookings</h5>
                  </div>
                  <div className='paginationValueBtn me-4 '>
                    <button onClick={handlefiveValue}>5</button>
                    <button onClick={handleTenValue}>10</button>
                    <button onClick={handleFiftenValue} >15</button>
                  </div>
                </div>
                <div className='container'>

                {
                    currentItems.map((val,index)=>(
                      <>
                      <div className='travelled_Datail container mt-2 mb-2'>

                        <div className='travelled_header d-flex justify-content-between'>
                          <h6 className=''><span className='travelled_bus_icon'>{val.busIcon}</span>{val.busDetail}</h6>
                          <p className="travelled_status3">{val.status3}</p>
                        </div>

                        <div className='travelled_city'>
                          <span>{val.from}</span><span>-</span><span>{val.to}</span>
                        </div>

                        <div className='travelled_footer d-flex justify-content-between'>
                         <div className='d-flex align-items-center justify-content-center'>
                            <span className='travelled_date_icon'>{val.dateIcon}</span>
                            <p className=''>{val.dateDetail}</p>
                         </div>
                          <div></div>
                        </div>
                      </div>
                      </>
                    ))
                  }
                  
                <div className='pagination'>
                    <button onClick={handlePrev} disabled={currentPage === 1} className='paginationPrev'>Prev</button>
                    {
                        Array.from({ length: totalPages }, (_, index) => (
                            <button onClick={() => handlePageClick(index + 1)} className={`paginationbtn ${currentPage === index + 1 ? "active" : ""}`}>{index + 1}</button>
                        ))
                    }
                    <button onClick={handleNext} disabled={currentPage === totalPages} className='paginationNext'>Next</button>
                </div>
                {/* <table style={{width:"100%"}}>
                    <tr>
                      <th className='border'>From</th>
                      <th className='border'>To</th>
                      <th className='border'>Date</th>
                      <th className='border'>Status</th>
                    </tr>
                    <tr>
                      <td className='border'>Delhi</td>
                      <td className='border'>Agra</td>
                      <td className='border'>05-05-24</td>
                      <td className='border'>Cancelled</td>
                    </tr>
                    <tr>
                      <td className='border'>Mumbai</td>
                      <td className='border'>Hyderabad</td>
                      <td className='border'>04-07-24</td>
                      <td className='border'>Cancelled</td>
                    </tr>
                  </table> */}
                </div>
              </div>

                <div className='d-flex justify-content-center p-4'>
                  <img src="/Images/Login Detail Section Images/1.png" height="80px" alt="" style={{ borderRadius: "50%" }} />
                </div>

                <button className='btn1'>Book Now</button></>}
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default MyBookings


const Sidebar = () => {



  const LoginSideBar = [
    {
      id: 1,
      icon: <GiTicket />,
      name: "My Booking",
      link: "/mybookings",
    },

    {
      id: 2,
      icon: <PiUsersThreeFill />,
      name: "My Travellers List",
      link: "/my_travellers_list",
    },

    {
      id: 3,
      icon: <FaUserCircle />,
      name: "My Profile",
      link: "/profile",
    },

    {
      id: 4,
      icon: <MdLocationPin />,
      name: "Billing Address",
      link: "/billing_address",
    },

    {
      id: 5,
      icon: <MdNotificationsActive />,
      name: "Notification",
      link: "/notification",
    },


  ];


  return (
    <>

      <div className="sidebar-div border ">
        <div
          className="   "
          style={{
            height: "auto",
            overflow: "hidden",
            margin: "0",
            padding: "0",
          }}
        >
          {LoginSideBar.map((val, index) => (
            <Link
              to={val.link}
              style={{
                display: "flex",
                color: "#331a4f",
                padding: "16px",
                fontSize: "15px",
                gap: "8px",
                textDecoration: "none",
                fontWeight: 500,
                color: "#156bd2"
              }}

              className='sidebar_link'
            >
              <div className='sidebar_icons ' style={{}}>{val.icon}</div>
              <div style={{ textDecoration: "none" }} className='sidebar_name'>{val.name}</div>
            </Link>
          ))}
        </div>

      </div>

    </>
  )
}


export { Sidebar }