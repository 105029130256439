import React from 'react'

const About = () => {
  return (
    <>
    
    <div className="about">
        <div className=" container ">
            <div className="about-top wow fadeInDown animated animated"   >
                <h2 style={{fontFamily:"Lato,sans-serif"}}>Our Story</h2>
                <p  ><b>BookUrSelf</b> is a unique portal that offers you access to all the divine temples of India, remedy rituals offered by the temples and also online bus booking service which provides you online bus ticket reservation services across india.</p>
            </div>
            <div className="about-mid row">
                <div className="col-lg-5 abt-lft " >
                    <h3 style={{fontFamily:"Lato,sans-serif"}}>Culture</h3>
                    <p >Easy access to divine facilitation and ecommerce credibility given the fact that all the Indians are unaware of the online facilities offered by the divine temples, we offer easy and unrestricted access to the facilities provided.
                        <b>BookUrSelf</b> not only provide access to remedy rituals and religious ceremonies, but also aims to make your trip without any glitches. In order to increase the faith and encourage the younger generation to actively participate, soldiers
                        to peacefully fulfil their desires.</p>
                    <p>It is our endeavour to provide high quality experience to our customers but also aim to educate everyone about the significance of their culture and importance of our divine temples. <b>BookUrSelf</b> is one portal where all can fulfil their
                        religious aspirations seeking divine intervention and paying homage to the powers of the almighty. By providing solutions to various problems by remedy poojas and fulfil your religious/ spiritual desires. We provide bookings over
                        a wide range of divine temples all over India.</p>
                </div>

                <div className="col-md-1"></div>
                <div className="col-lg-5 abt-lft " >
                    <h3 style={{fontFamily:"Lato,sans-serif"}}>Technology</h3>
                    <p>
                        <b>BookUrSelf</b> has <b>80,000 + routes</b> and <b>3000+ bus operator</b> are in serving people to reach their destinations with safety and comfort. <b>BookUrSelf</b> providing best deals on your finger tip. Passengers are able to find a wide range of filters which enable them
                        to choose bus operator, type of bus, ticket fares, boarding point, dropping point etc.
                    </p>
                    <p >Our customer support team will resolve customer issues with in time, through call or mail. We respect your feedback and improve our services. Secure payment gateway with payment options helps safe and secure payment transaction.</p>
                    <p><b>BookUrSelf</b> uses billing information only for the purpose of completing travel booking that the customer initiates on <b>BookUrSelf's website or mobile apps (Android).</b></p>

                </div>
               
            </div>
            
            
        </div>
    </div> 

    </>
  )
}

export default About