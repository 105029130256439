import React, { useState } from 'react'
import { Sidebar } from './MyBookings'
import './LoginPageCss/loginpagedetail.css'

const BillingAddress = () => {

  const [inputVal, setInputVal] = useState("") 
  return (
    <>

      <div className="container mt-5 login_head_section">
      {/* G:\new bookurself\bookurself\src\components\LoginPageDetails\\loginpagedetail.css */}

        <div className="login_section">

          <div className="login_sidebar">
            <Sidebar  />
          </div>

          <div className="login_details">

            <div className='my_booking_section container '>

              <div className="row pt-4">
                <div className='col-md-6 '>
                  <div>
                    <label htmlFor="">Address</label>
                  </div>
                  <input type="text" 
                  className='ba_input'
                   value={inputVal} 
                   onChange={(e)=>setInputVal(e.target.value)}
                   />
                </div>

                <div className='col-md-6'>
                  <div>
                    <label htmlFor="">PIN Code</label>
                  </div>
                  <input type="text" className='ba_input' />
                </div>
              </div>

              <div className='row mt-3'>

                <div className='col-md-6'>
                  <div>
                    <label htmlFor="">State</label>
                  </div>
                  <input type="text" className='ba_input' />
                </div>

                <div className='col-md-6'>
                  <div>
                    <label htmlFor="">City</label>
                  </div>
                  <input type="text"  className='ba_input' />
                </div>
              </div>
              <button className='mt-4 mb-4  btn2' >Save</button>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default BillingAddress