import React, { useState, useEffect } from 'react'
import "./calendar.css"
import { FaLongArrowAltRight,FaLongArrowAltLeft } from "react-icons/fa";
import { setMonth } from 'date-fns';


const Pcalendar = () => {

    const [currentDate, setCurrentDate] = useState(new Date());
    const [daysInMonth, setDaysInMonth] = useState([]);
    const [startDay, setStartDay] = useState(0);
    const [selectedDate, setSelectedDate] = useState(null);
    const [ddd, setddd] = useState("date")


    useEffect(() => {
      
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        const date = new Date (year, month, 1)
        const days = [];
        
        while(date.getMonth() === month){
            days.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }
        
        setDaysInMonth(days);
        setStartDay(new Date(year, month, 1).getDay())
    }, [currentDate])
    
    const dayNames = ["MON","TUE","WED","THU","FRI","SAT","SUN"];

    const prevMonth =()=>{

        if(currentDate.getMonth() >  new Date().getMonth()){
            setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - 1)));
        }
       
    }

    const nextMonth =()=>{
        if(currentDate.getMonth() === new Date().getMonth()){
            setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + 1)));
        }
       
    }

    const handleDateClick =(day)=>{
        setSelectedDate(day);
        setddd(day.toLocaleDateString('en-GB',{dateStyle : "short"})
    )

    }

  return (
   <>
   {/* <h1>{ddd}</h1> */}
   <div className='calendar'>
    <div className='header'>
        <button className={`${currentDate.getMonth() === new Date().getMonth() ? "chnbtncl btncl":"btncl"}`} onClick={prevMonth}><FaLongArrowAltLeft/></button>
        <span>{currentDate.toLocaleString('default',{month:'long'})}{' '}
            {currentDate.getFullYear()}
        </span>
        <button onClick={nextMonth} className={`${currentDate.getMonth() >  new Date().getMonth() ? "chnbtncl btncl":"btncl"}`}><FaLongArrowAltRight/></button>
    </div>

    <div className='day-names'>
        {
            dayNames.map((day)=>(
                <div key={day} className='day-name'>
                    {day}
                </div>
            ))
        }
    </div>

    <div className='days'>
        {Array.from({length:startDay}).map((val,index)=>(

            <div key={index} className='empty-day'></div>
        ))}

{/* ${day.getDate() && day.getMonth() > new Date().getMonth() ? "hello":""} */}

        {daysInMonth.map((day)=>(
            <div
            key={day}
            className={`day ${day.getDate() === new Date().getDate() && day.getMonth() === new Date().getMonth() ? 'today':''} ${selectedDate && day.toDateString() === selectedDate.toDateString() ? 'selected' : ''} ${day.getDate() > new Date().getDate() && day.getMonth() === new Date().getMonth() ? 'hello':""} 
            ${day.getMonth() > new Date().getMonth() && day.getDate() <=  new Date().getDate() ?"hello":''}`}
            onClick={()=>handleDateClick(day)}
            >
                {day.getDate()}
            </div>
        ))}
       
    </div>

   </div>
   </>
  )
}

export default Pcalendar